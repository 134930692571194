import React, { useEffect, useState } from "react";
import {
    Form,
    Row,
    Col,
    Card,
    InputGroup,
    FormControl,
    Button,
    Image,
} from "react-bootstrap";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import gfm from "remark-gfm";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import ReactMarkdown from "react-markdown";
import { apiClient, httpRequestWithFile } from "../../backend/http";
import { useSelector } from "react-redux";
import Select from "react-select";

import "./QuestionForm.css";

export const questionTypes = [
    {
        short: "float",
        long: "FloatAnswerQuestion",
        ru: "Ответ числом",
    },
    {
        short: "string",
        long: "StringAnswerQuestion",
        ru: "Ответ строкой",
    },
    {
        short: "choice",
        long: "ChoiceAnswerQuestion",
        ru: "Выбор ответа",
    },
    // {
    //     short: 'exam',
    //     long: 'ExamQuestion',
    //     ru: 'Задание экзамена'
    // }
];

// https://www.smashingmagazine.com/2020/02/html-drag-drop-api-react/
// https://github.com/remarkjs/react-markdown

export const QuestionForm = ({
    onSubmit,
    question = null, // если вопрос редактируется, то он вставляется сюда
    initialNodes = null, // если вопрос создается с нуля в графе, то в форме сразу выбирается вершина
    initialThemes = null, // если вопрос создается с нуля в рубрикаторе, то в форме сразу выбирается тема
}) => {
    const globalData = useSelector((store) => store.methodics);

    const selectedSubjectId = globalData.selectedSubjectId;
    const selectedSubject = globalData.subjects.find(
        (obj) => obj.id === selectedSubjectId
    );
    const subjectGraph = selectedSubject.graph;

    const nodesAll = subjectGraph.nodes.map((node) => {
        return {
            value: node.id,
            label: node.data.title,
        };
    });

    const themesAll = selectedSubject.themes.map((theme) => {
        return {
            value: theme.id,
            label: theme.theme.title,
        };
    });

    // Базовый набор для опций в ChoiceQuestion
    const defaultOptions = [
        { option_text: "", is_true: false },
        { option_text: "", is_true: false },
    ];

    const [questionInitial, setQuestionInitial] = useState();

    const [questionText, setQuestionText] = useState("");
    const [questionImageUrl, setQuestionImageUrl] = useState();
    const [questionImage, setQuestionImage] = useState();
    const [explanationText, setExplanationText] = useState("");
    const [explanationImageUrl, setExplanationImageUrl] = useState();
    const [explanationImage, setExplanationImage] = useState();

    const [questionType, setQuestionType] = useState("float");
    const [answer, setAnswer] = useState("");
    const [answerList, setAnswerList] = useState([]);
    const [options, setOptions] = useState(defaultOptions);

    // Only for Exam Task
    const [maxScore, setMaxScore] = useState("1");
    const [orderImportance, setOrderImportance] = useState(true);

    // Выбранные в поле вершины
    const [nodes, setNodes] = useState([]);
    const [themes, setThemes] = useState([]);

    const [policyAll, setPolicyAll] = useState([]);

    const [isAnswerValid, setIsAnswerValid] = useState(true);
    const [validated, setValidated] = useState(false);

    // Нажата ли кнопка удаления в первый раз?
    const [deleteStep, setDeleteStep] = useState(false);

    useEffect(() => {
        const qu = Object.assign({}, question);
        setQuestionInitial(qu);
    }, [question]);

    useEffect(() => {
        let initial = Object.assign({}, questionInitial);

        if (initial) {
            if (initial.question_text) {
                setQuestionText(initial.question_text);
            }
            if (initial.explanation_text) {
                setExplanationText(initial.explanation_text);
            }
            if (initial.image) {
                setQuestionImageUrl(
                    initial.image.replace(
                        "/media",
                        "https://stagging.adaptaki.ru/media"
                    )
                );
            }
            if (initial.explanation_image) {
                setExplanationImageUrl(
                    initial.explanation_image.replace(
                        "/media",
                        "https://stagging.adaptaki.ru/media"
                    )
                );
            }

            // TODO: check for exam question, поставить значение 'string'
            if (initial.question_type === "FloatAnswerQuestion") {
                setQuestionType("float");
                setAnswer(initial.right_answer.toString());
            } else if (initial.question_type === "StringAnswerQuestion") {
                let array = initial.right_answer.split(";");
                if (array.length > 1) {
                    setAnswerList(array);
                }
                setQuestionType("string");
                setAnswer(initial.right_answer);
            } else if (initial.question_type === "ChoiceQuestion") {
                setQuestionType("choice");
                setOptions(initial.options_data);
            } else if (initial.question_type === "ExamQuestion") {
                setQuestionType("string");
                setAnswer(initial.right_answer);
                setMaxScore(initial.maximum);
                setOrderImportance(initial.order_importance);
            }
        } else {
            setQuestionType("string");
            setAnswer("");
        }
    }, [questionInitial]);

    // Загружаем список нод и тем, заполняем селекты для них
    useEffect(() => {
        apiClient(`questions/checking-policy/`)
            .then((res) => res.json())
            .then((json) => {
                console.log("policy", json.policies);
                setPolicyAll(json.policies);
            });
    }, [question, initialNodes, initialThemes, selectedSubjectId]);

    useEffect(() => {
        // Устанавливаем ноды
        if (question) {
            let initialNodesForSelect = [];
            for (let node of question.nodes) {
                let n = nodesAll.find((element) => element.value === node.id);
                if (n !== undefined) {
                    initialNodesForSelect.push(n);
                }
            }
            setNodes(initialNodesForSelect);
        } else if (initialNodes) {
            let initialNodesForSelect = [];
            for (let nodeId of initialNodes) {
                let n = nodesAll.find((element) => element.value === nodeId);
                if (n !== undefined) {
                    initialNodesForSelect.push(n);
                }
            }
            setNodes(initialNodesForSelect);
        }
    }, [question, initialNodes]);

    useEffect(() => {
        // Устанавливаем темы
        if (question) {
            let initialThemesForSelect = [];
            for (let theme of question.themes) {
                let n = themesAll.find((element) => element.value === theme.id);
                if (n !== undefined) {
                    initialThemesForSelect.push(n);
                }
            }
            setThemes(initialThemesForSelect);
        } else if (initialThemes) {
            let initialThemesForSelect = [];
            for (let themeId of initialThemes) {
                let n = themesAll.find((element) => element.value === themeId);
                if (n !== undefined) {
                    initialThemesForSelect.push(n);
                }
            }
            setThemes(initialThemesForSelect);
        }
    }, [question, initialThemes]);

    const onQuestionImageChange = (e) => {
        console.log(e.target.files[0]);
        setQuestionImage(e.target.files[0]);
        setQuestionImageUrl(URL.createObjectURL(e.target.files[0]));
    };

    const onExplanationImageChange = (e) => {
        console.log(e.target.files[0]);
        setExplanationImage(e.target.files[0]);
        setExplanationImageUrl(URL.createObjectURL(e.target.files[0]));
    };

    // Validate and send data
    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;
        }

        if (questionType === "float") {
            event.preventDefault();
            event.stopPropagation();

            let _answer = answer.replace(",", ".");
            if (isNaN(+_answer)) {
                setIsAnswerValid(false);
                setValidated(false);
                return;
            }
        }

        event.preventDefault();
        event.stopPropagation();
        setIsAnswerValid(true);
        setValidated(false);

        let plainNodesIdsArray = nodes.map((node) => {
            return node.value;
        });

        let plainThemesIdsArray = themes.map((theme) => {
            return theme.value;
        });

        if (question) {
            // Edit
            const requestData = {
                question: {
                    nodes_id: plainNodesIdsArray,
                    themes_id: plainThemesIdsArray,
                    question_text: questionText,
                    explanation_text: explanationText,
                },
            };

            // добавляем отредактированый ответ или список опций
            if (questionType === "choice") {
                let deleteOptionsArray = [];
                let editOptionsArray = [];
                for (let oldOption of question.options_data) {
                    // попробовать найти этот айдишник в массиве
                    let found = options.find(function (element) {
                        return element.id === oldOption.id;
                    });
                    // если его нет, то добавить в список удаляемых
                    if (found === undefined) {
                        deleteOptionsArray.push(oldOption.id);
                        // если есть - осуществить проверку и оставить только отредактированные поля
                    } else {
                        // заводим новый объект, куда будем складывать измененные опции
                        let potentialEditedOption = {};
                        for (const [key, value] of Object.entries(found)) {
                            if (key !== "question" && key !== "file") {
                                if (value !== oldOption[key]) {
                                    potentialEditedOption[key] = value;
                                }
                            }
                        }

                        if (found.file) {
                            console.log(
                                "Option",
                                found.id,
                                "has file: ",
                                found.file
                            );
                            let formData = new FormData();
                            formData.append("upload", found.file);

                            httpRequestWithFile(
                                `options/${found.id}/image/`,
                                formData
                            ).then((imgRes) => {
                                console.log(imgRes);
                            });
                        }

                        if (Object.keys(potentialEditedOption).length !== 0) {
                            potentialEditedOption.id = found.id;
                            editOptionsArray.push(potentialEditedOption);
                        }
                    }
                }

                let newOptionsArray = [];
                for (let o of options) {
                    if (!o.id) {
                        newOptionsArray.push({
                            question: question.id,
                            ...o,
                        });
                    }
                }

                requestData.question.options = {
                    delete: deleteOptionsArray,
                    edit: editOptionsArray,
                    new: newOptionsArray,
                };
            } else if (questionType === "float") {
                requestData.question.right_answer = parseFloat(answer);
            } else if (questionType === "string") {
                if (question.question_type === "ExamQuestion") {
                    requestData.question.maximum = parseInt(maxScore);
                    requestData.question.order_importance = orderImportance;
                }

                // Replace apostrophs на U+2019
                let _answer = answer;
                // https://habr.com/ru/post/25531/
                _answer.replace("'", "’"); // открывающая ма́рровская кавычка
                _answer.replace("‘", "’"); // закрывающая ма́рровская кавычка
                _answer.replace("´", "’"); // знак ударения
                _answer.replace("`", "’"); // гравис

                requestData.question.right_answer = _answer;
            }

            apiClient(`questions/${question.id}/`, requestData, "PUT")
                .then((res) => res.json())
                .then((questionJson) => {
                    if (questionJson) {
                        if (questionImage) {
                            let formData = new FormData();
                            formData.append("upload", questionImage);

                            httpRequestWithFile(
                                `questions/${question.id}/image/`,
                                formData
                            ).then((imgRes) => {
                                console.log(imgRes);
                            });
                        }

                        if (explanationImage) {
                            let formData = new FormData();
                            formData.append("upload", explanationImage);

                            httpRequestWithFile(
                                `questions/${question.id}/explanation-image/`,
                                formData
                            ).then((imgRes) => {
                                console.log(imgRes);
                            });
                        }
                        onSubmit();
                    } else {
                        alert("Ошибка при редактировании вопроса");
                    }
                });
        } else {
            // create

            let _answer = answer;
            if (questionType === "string") {
                // Replace apostrophs на U+2019
                // https://habr.com/ru/post/25531/
                _answer.replace("'", "’"); // открывающая ма́рровская кавычка
                _answer.replace("‘", "’"); // закрывающая ма́рровская кавычка
                _answer.replace("´", "’"); // знак ударения
                _answer.replace("`", "’"); // гравис
            }

            // Заполняем базовые поля
            const requestData = {
                question: {
                    nodes_id: plainNodesIdsArray,
                    themes_id: plainThemesIdsArray,
                    question_text: questionText,
                    explanation_text: explanationText,
                    right_answer: _answer,
                    options: options,
                },
            };

            let typeOfQuestion = questionType;
            // Проверка на принадлежность к examquestion
            if (typeOfQuestion === "string") {
                if (maxScore !== "1" || !orderImportance) {
                    typeOfQuestion = "exam";
                    requestData.question.maximum = parseInt(maxScore);
                    requestData.question.order_importance = orderImportance;
                    requestData.question.checking_policy = policyAll[0][0];
                }
            }

            requestData.type = typeOfQuestion;

            console.log("Create question", requestData);

            apiClient(`questions/`, requestData, "POST")
                .then((res) => res.json())
                .then((questionJson) => {
                    console.log("question after create", questionJson);
                    if (questionJson.success) {
                        setQuestionText("");
                        setExplanationText("");
                        setAnswer("");
                        setOptions(defaultOptions);

                        if (questionImage) {
                            let formData = new FormData();
                            formData.append("upload", questionImage);

                            httpRequestWithFile(
                                `questions/${questionJson.question.id}/image/`,
                                formData
                            ).then((imgRes) => {
                                console.log(imgRes);
                                onSubmit();
                            });
                        }

                        if (explanationImage) {
                            let formData = new FormData();
                            formData.append("upload", explanationImage);

                            httpRequestWithFile(
                                `questions/${questionJson.question.id}/explanation-image/`,
                                formData
                            ).then((imgRes) => {
                                console.log(imgRes);
                            });
                        }

                        onSubmit();
                    } else {
                        alert("Ошибка при создании вопроса");
                    }
                });
        }
    };

    const onDelete = () => {
        apiClient(`questions/${question.id}/`, null, "DELETE").then(
            (questionJson) => {
                onSubmit();
            }
        );
    };

    const optionsList = options.map((option, index) => {
        console.log("Question options", options);
        return (
            <div key={index.toString()} className={"mb-3"}>
                <InputGroup>
                    <InputGroup.Checkbox
                        checked={options[index].is_true}
                        onChange={() => {
                            let items = [...options];
                            let item = { ...items[index] };
                            item.is_true = !item.is_true;
                            items[index] = item;
                            setOptions(items);
                        }}
                    />
                    <FormControl
                        as="textarea"
                        rows={1}
                        value={options[index].option_text}
                        onChange={(e) => {
                            let items = [...options];
                            let item = { ...items[index] };
                            item.option_text = e.target.value;
                            items[index] = item;
                            setOptions(items);
                        }}
                    />
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            let items = [...options];
                            items.splice(index, 1);
                            setOptions(items);
                        }}
                    >
                        Удалить
                    </Button>
                </InputGroup>
                {option.id && (
                    <Form.Control
                        type="file"
                        size={"sm"}
                        onChange={(e) => {
                            let items = [...options];
                            let item = { ...items[index] };
                            item.file = e.target.files[0];
                            items[index] = item;
                            setOptions(items);
                        }}
                    />
                )}
                {(option.file ||
                    options[index].option_text ||
                    options[index].option_image) && (
                    <Card>
                        <Card.Body>
                            <ReactMarkdown
                                style={{ margin: 0 }}
                                remarkPlugins={[remarkMath]}
                                rehypePlugins={[rehypeKatex, rehypeRaw]}
                                children={options[index].option_text.replace(
                                    "/media",
                                    "https://stagging.adaptaki.ru/media"
                                )}
                            />
                            {option.file ? (
                                <Image
                                    src={URL.createObjectURL(option.file)}
                                    className={"mw-100"}
                                />
                            ) : option.option_image ? (
                                <Image
                                    src={option.option_image.replace(
                                        "/media",
                                        "https://stagging.adaptaki.ru/media"
                                    )}
                                    rounded
                                    className={"mw-100 mb-3"}
                                />
                            ) : (
                                false
                            )}
                        </Card.Body>
                    </Card>
                )}
            </div>
        );
    });

    const questionTypeOptions = () => {
        return questionTypes.map((type) => {
            return (
                <option key={type.short} value={type.short}>
                    {type.ru}
                </option>
            );
        });
    };

    const answersList = answerList.map((ans, index) => {
        return (
            <InputGroup className="mb-3" key={index.toString()}>
                <Form.Control
                    required
                    type="text"
                    placeholder="Введи правильный ответ"
                    value={answerList[index]}
                    onChange={(e) => {
                        let items = [...answerList];
                        let item = e.target.value;
                        items[index] = item;
                        setAnswerList(items);
                        setAnswer(items.join(";"));
                    }}
                />
                <Button
                    variant="outline-secondary"
                    onClick={() => {
                        let newAnswerList = [...answerList];
                        newAnswerList.splice(index, 1);
                        if (newAnswerList.length === 1) {
                            setAnswer(newAnswerList[0]);
                            setAnswerList([]);
                        } else {
                            setAnswer(newAnswerList.join(";"));
                            setAnswerList(newAnswerList);
                        }
                    }}
                >
                    Удал.
                </Button>
            </InputGroup>
        );
    });

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Прикрепленные вершины графа</Form.Label>
                        <Select
                            value={nodes}
                            onChange={(e) => setNodes(e)}
                            options={nodesAll}
                            isMulti={true}
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Прикрепленные темы из рубрикатора
                        </Form.Label>
                        <Select
                            value={themes}
                            onChange={(e) => setThemes(e)}
                            options={themesAll}
                            isMulti={true}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Текст задания</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={6}
                            value={questionText}
                            onChange={(e) => setQuestionText(e.target.value)}
                        />
                        {/*<div className={'drag-drop-zone'} onDrop={e => handleDrop(e)}>*/}
                        {/*    <p>Drag files here to upload</p>*/}
                        {/*</div>*/}
                        <Form.Text>
                            <a
                                href="https://www.markdownguide.org/basic-syntax/"
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                Шпаргалка по Markdown...
                            </a>
                        </Form.Text>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Изображение в условии задания
                            </Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => onQuestionImageChange(e)}
                            />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Как будет выглядеть</Form.Label>
                        <Card>
                            <Card.Body>
                                <ReactMarkdown
                                    className={"renderedWrapper"}
                                    remarkPlugins={[remarkMath, gfm]}
                                    rehypePlugins={[rehypeKatex, rehypeRaw]}
                                    children={questionText}
                                />
                                {questionImageUrl && (
                                    <>
                                        <Image
                                            src={questionImageUrl}
                                            rounded
                                            className={"mw-100"}
                                        />
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col}>
                        <Form.Label>Пояснение</Form.Label>
                        <Form.Control
                            required
                            as="textarea"
                            rows={3}
                            value={explanationText}
                            onChange={(e) => setExplanationText(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Добавьте пояснение к заданию
                        </Form.Control.Feedback>
                        <Form.Group className="mb-3">
                            <Form.Label>Изображение в пояснении</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => onExplanationImageChange(e)}
                            />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Как будет выглядеть</Form.Label>
                        <Card>
                            <Card.Body>
                                <ReactMarkdown
                                    remarkPlugins={[remarkMath, gfm]}
                                    rehypePlugins={[rehypeKatex, rehypeRaw]}
                                    children={explanationText}
                                />
                                {explanationImageUrl && (
                                    <>
                                        <Image
                                            src={explanationImageUrl}
                                            rounded
                                            className={"mw-100"}
                                        />
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Тип задания</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                disabled={question ? true : false}
                                onChange={(e) =>
                                    setQuestionType(e.target.value)
                                }
                                value={questionType}
                                required
                            >
                                {questionTypeOptions()}
                            </Form.Select>
                        </Form.Group>
                        {questionType === "string" && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Максимальный балл</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Максимальный балл"
                                        value={maxScore}
                                        onChange={(e) => {
                                            if (
                                                e.target.value !== "1" ||
                                                !orderImportance
                                            ) {
                                                // Если был список вопросов, то чикаем его
                                                if (answerList.length > 1) {
                                                    setAnswer(answerList[0]);
                                                    setAnswerList([]);
                                                }
                                            }
                                            setMaxScore(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        checked={orderImportance}
                                        label="Порядок важен?"
                                        onChange={() => {
                                            if (
                                                maxScore !== "1" ||
                                                orderImportance
                                            ) {
                                                // Если был список вопросов, то чикаем его
                                                if (answerList.length > 1) {
                                                    setAnswer(answerList[0]);
                                                    setAnswerList([]);
                                                }
                                            }
                                            setOrderImportance(
                                                !orderImportance
                                            );
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}
                    </Col>
                    {questionType === "choice" && (
                        <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Варианты ответа</Form.Label>
                            {optionsList}
                            <Button
                                className={"mt-3"}
                                onClick={() => {
                                    let oldOptions = [...options];
                                    oldOptions.push({
                                        option_text: "",
                                        is_true: false,
                                    });
                                    setOptions(oldOptions);
                                }}
                            >
                                + добавить вариант ответа
                            </Button>
                        </Form.Group>
                    )}

                    {questionType === "float" && (
                        <Form.Group
                            as={Col}
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>Правильный ответ</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Введи правильный ответ"
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                isInvalid={!isAnswerValid}
                            />
                            <Form.Control.Feedback type="invalid">
                                Некорректный ответ (не число)
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    {questionType === "string" && (
                        <>
                            <Form.Group
                                as={Col}
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Правильный ответ</Form.Label>
                                {answerList.length !== 0 ? (
                                    <>
                                        {answersList}
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                setAnswerList([
                                                    ...answerList,
                                                    "",
                                                ])
                                            }
                                        >
                                            +
                                        </Button>
                                    </>
                                ) : (
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Введи правильный ответ"
                                            value={answer}
                                            onChange={(e) =>
                                                setAnswer(e.target.value)
                                            }
                                            isInvalid={!isAnswerValid}
                                        />
                                        {maxScore === "1" && orderImportance ? (
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => {
                                                    if (
                                                        answer &&
                                                        answer !== ""
                                                    ) {
                                                        setAnswerList([
                                                            answer,
                                                            "",
                                                        ]);
                                                    }
                                                }}
                                            >
                                                +
                                            </Button>
                                        ) : (
                                            false
                                        )}
                                    </InputGroup>
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Некорректный ответ (не число)
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    )}
                </Row>
                {question ? (
                    <>
                        <Button type="submit" variant={"success"}>
                            Сохранить
                        </Button>
                        {deleteStep ? (
                            <>
                                <Button
                                    className={"mx-2"}
                                    variant={"info"}
                                    onClick={() => setDeleteStep(false)}
                                >
                                    Отменить удаление
                                </Button>
                                <Button
                                    className={"mx-2"}
                                    variant={"danger"}
                                    onClick={() => {
                                        setDeleteStep(false);
                                        onDelete();
                                    }}
                                >
                                    Подтверди удаление
                                </Button>
                            </>
                        ) : (
                            <Button
                                className={"mx-2"}
                                variant={"danger"}
                                onClick={() => setDeleteStep(true)}
                            >
                                Удалить
                            </Button>
                        )}
                    </>
                ) : (
                    <Button type="submit" variant={"success"}>
                        Создать
                    </Button>
                )}
            </Form>
        </>
    );
};
