import React from "react";
import { useState } from "react";
import {
    Card,
    Spinner,
    Button,
    Modal,
    Row,
    Col,
    Offcanvas,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { nodeTitles } from "./helpers";
import Creatable from "react-select/creatable";
import Select from "react-select";
import { NodeForm } from "../../../components/methodics/NodeForm";
import {
    addNewNodeForSubject,
    updateNodeDataforSubject,
    deleteNodeforSubject,
    deleteEdgeforSubject,
    addNewEdgeForSubject,
} from "../../../features/methodics/methodicsReducer";
import {
    postNode,
    updateNode,
    deleteNode,
    deleteEdge,
    addLink,
} from "../../../features/methodics/methodicsApi";

export const GraphItems = ({
    selectedNodeId,
    selectedEdgeId,
    selectedSubject,
    onConceptSelect,
    setSelectedNodeId,
    setSelectedEdgeId,
    nodesForSelect,
    avaiableNodesForSelect,
    focusMode,
    user,
    secondNode,
    setSecondNode,
    showAddEdgeModal,
    setShowAddEdgeModal,
}) => {
    const dispatch = useDispatch();

    const subjectGraph = selectedSubject.graph;

    const selectedConceptTagId = selectedSubject.selectedConceptId;
    const subjectConcepts = selectedSubject.concepts;
    const selectedConcept = selectedSubject.concepts.find(
        (obj) => obj.id === selectedConceptTagId
    );

    const selectedNode = subjectGraph.nodes.find(
        (obj) => obj.id === selectedNodeId
    );
    const selectedEdge = subjectGraph.edges.find(
        (obj) => obj.id === selectedEdgeId
    );

    const [newNodeTitle, setNewNodeTitle] = useState("");
    const [isNodeAdding, setIsNodeAdding] = useState(false);
    const [isEdgeAdding, setIsEdgeAdding] = useState(false);
    const [isEdgeDeleting, setIsEdgeDeleting] = useState(false);
    const [isNodeDeleting, setIsNodeDeleting] = useState(false);

    const [showDeleteEdgeModal, setShowDeleteEdgeModal] = useState(false);
    const [showCreateNodeModal, setShowCreateNodeModal] = useState(false);
    const [showEditNodeModal, setShowEditNodeModal] = useState(false);
    const [showDeleteNodeModal, setShowDeleteNodeModal] = useState(false);

    const [addParent, setAddParent] = useState(true);
    const [addChild, setAddChild] = useState(false);

    // Data managers
    const findConceptById = (conceptId) => {
        if (conceptId) {
            return subjectConcepts.find((concept) => concept.id === conceptId);
        }
        return null;
    };

    // Создаем вершину
    const addNode = (node) => {
        setIsNodeAdding(true);
        let nodeData = {
            title: node.title,
            type: node.type,
            testability: node.testability,
            subject: selectedSubject.id,
            concept: node.concept,
        };
        postNode(nodeData).then((newNode) => {
            dispatch(
                addNewNodeForSubject({
                    id: selectedSubject.id,
                    node: { id: newNode.id, data: newNode, questions_count: 0 },
                })
            );
            if (!addParent && !addChild) {
                setSelectedNodeId(newNode.id);
            }
            setShowCreateNodeModal(false);

            if (!showAddEdgeModal) {
                // если речь не идет о добавлении вершины через интерфейс добавления связи
                // то просто выбираем ее
                setSelectedNodeId(newNode.id);
            } else {
                setSecondNode({ value: newNode.id, label: newNode.title });
            }
            setIsNodeAdding(false);
        });
    };

    // удаляем вершину
    const onDeleteNode = () => {
        setIsNodeDeleting(true);
        deleteNode(selectedNodeId).then(() => {
            setSelectedNodeId(null);
            dispatch(
                deleteNodeforSubject({
                    subjectId: selectedSubject.id,
                    nodeId: selectedNodeId,
                })
            );
            setShowDeleteNodeModal(false);
            setIsNodeDeleting(false);
        });
    };

    // Редактируем вершину
    const editNode = async (node) => {
        let nodeBody = {
            title: node.title,
            type: node.type,
            testability: node.testability,
            concept: node.concept,
        };
        updateNode(selectedNodeId, nodeBody).then(() => {
            dispatch(
                updateNodeDataforSubject({
                    subjectId: selectedSubject.id,
                    nodeId: selectedNodeId,
                    body: nodeBody,
                })
            );
        });
    };

    // удаляем связь
    const onDeleteEdge = () => {
        setIsEdgeDeleting(true);
        deleteEdge(selectedEdgeId).then(() => {
            setSelectedEdgeId(null);
            dispatch(
                deleteEdgeforSubject({
                    subjectId: selectedSubject.id,
                    edgeId: selectedEdgeId,
                })
            );
            setShowDeleteEdgeModal(false);
            setIsEdgeDeleting(false);
        });
    };

    const onAddEdge = () => {
        let newLink = {};
        if (addChild) {
            newLink = { from: selectedNodeId, to: secondNode.value };
        } else if (addParent) {
            newLink = { from: secondNode.value, to: selectedNodeId };
        }
        setIsEdgeAdding(true);
        addLink(newLink).then((edge) => {
            dispatch(
                addNewEdgeForSubject({ id: selectedSubject.id, edge: edge })
            );
            setIsEdgeAdding(false);
            setSecondNode(null);
            setAddChild(false);
            setAddParent(false);
            setShowAddEdgeModal(false);
        });
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    {selectedNodeId && selectedNode ? (
                        <>
                            <Card.Text>
                                Выбранная вершина:
                                <br />
                                <b>{selectedNode.data.title}</b>
                            </Card.Text>
                            <Card.Text>
                                Концепт: <br />
                                {selectedNode.data.concept ? (
                                    <>
                                        <b>
                                            {
                                                findConceptById(
                                                    selectedNode.data.concept
                                                ).concept.title
                                            }
                                        </b>
                                        {selectedConceptTagId &&
                                        selectedNode.data.concept !==
                                            selectedConceptTagId ? (
                                            <Button
                                                onClick={() =>
                                                    onConceptSelect(
                                                        findConceptById(
                                                            selectedNode.data
                                                                .concept
                                                        ).id
                                                    )
                                                }
                                                size={"sm"}
                                                variant={"secondary"}
                                            >
                                                Выбрать этот концепт
                                            </Button>
                                        ) : (
                                            false
                                        )}
                                    </>
                                ) : (
                                    <b>Без концепта</b>
                                )}
                            </Card.Text>
                            <Card.Text>
                                {selectedNode.testability && (
                                    <>
                                        Пров. (Заданий:{" "}
                                        {selectedNode.questions_count}),{" "}
                                    </>
                                )}
                                id{selectedNodeId},{" "}
                                {nodeTitles[selectedNode.data.type - 1]}
                            </Card.Text>
                            {user.role && user.role < 3 ? (
                                <Card.Text>
                                    <Button
                                        className={"me-2 btn-sm"}
                                        variant={"outline-primary"}
                                        onClick={() =>
                                            setShowEditNodeModal(true)
                                        }
                                    >
                                        Ред.
                                    </Button>
                                    {selectedNode.questions_count === 0 ? (
                                        <Button
                                            className={"me-2 btn-sm"}
                                            variant={"outline-danger"}
                                            onClick={() =>
                                                setShowDeleteNodeModal(true)
                                            }
                                        >
                                            Удалить
                                        </Button>
                                    ) : (
                                        false
                                    )}
                                </Card.Text>
                            ) : (
                                false
                            )}
                            {user.role && user.role < 3 ? (
                                <Card.Text>
                                    <Button
                                        className={"me-2 btn-sm"}
                                        variant={"outline-primary"}
                                        onClick={() =>
                                            setShowAddEdgeModal(true)
                                        }
                                    >
                                        Добавить cвязь
                                    </Button>
                                </Card.Text>
                            ) : (
                                false
                            )}
                        </>
                    ) : (
                        <>
                            <p>Выбери вершину</p>
                            {user.role && user.role < 3 ? (
                                <Creatable
                                    isClearable
                                    onChange={(e) => {
                                        e !== null
                                            ? setSelectedNodeId(e.value)
                                            : setSelectedNodeId(null);
                                    }}
                                    onCreateOption={(inputValue) => {
                                        console.log(
                                            "Создание вершины с заголовком: ",
                                            inputValue
                                        );
                                        setNewNodeTitle(inputValue);
                                        setShowCreateNodeModal(true);
                                    }}
                                    options={
                                        selectedConceptTagId || focusMode
                                            ? avaiableNodesForSelect
                                            : nodesForSelect
                                    }
                                />
                            ) : (
                                <Select
                                    onChange={(e) => {
                                        e !== null
                                            ? setSelectedNodeId(e.value)
                                            : setSelectedNodeId(null);
                                    }}
                                    options={
                                        selectedConceptTagId || focusMode
                                            ? avaiableNodesForSelect
                                            : nodesForSelect
                                    }
                                />
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>
            {selectedEdgeId ? (
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Text>
                            Выбрана связь: id {selectedEdgeId}{" "}
                            {JSON.stringify(selectedEdge)}
                        </Card.Text>
                        {user.role && user.role < 3 ? (
                            <Card.Text>
                                <Button
                                    className={"me-2 btn-sm"}
                                    variant={"danger"}
                                    onClick={() => setShowDeleteEdgeModal(true)}
                                >
                                    Удалить
                                </Button>
                            </Card.Text>
                        ) : (
                            false
                        )}
                    </Card.Body>
                </Card>
            ) : (
                false
            )}

            <Modal
                show={showCreateNodeModal}
                onHide={() => setShowCreateNodeModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Создание вершины</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NodeForm
                        initialConcepts={subjectConcepts}
                        initialConcept={selectedConcept}
                        subject={selectedSubject}
                        initialTitle={newNodeTitle}
                        isFetching={isNodeAdding}
                        onSubmit={(node) => {
                            console.log(
                                "получен callback от формы создания вершины: ",
                                node
                            );
                            addNode(node);
                        }}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showEditNodeModal}
                onHide={() => setShowEditNodeModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование вершины</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedNode && (
                        <NodeForm
                            node={selectedNode}
                            initialConcepts={subjectConcepts}
                            onSubmit={(node) => {
                                setShowEditNodeModal(false);
                                console.log(
                                    "Форма редактирования вершины выдала: ",
                                    node
                                );
                                editNode(node);
                            }}
                        />
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteEdgeModal}
                onHide={() => setShowDeleteEdgeModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ты уверен в удалении связи?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Придется ждать пересборки графа много времени!!!</p>
                    {isEdgeDeleting ? (
                        <Spinner animation="grow" />
                    ) : (
                        <Button
                            variant={"danger"}
                            onClick={() => onDeleteEdge()}
                        >
                            Удалить
                        </Button>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteNodeModal}
                onHide={() => setShowDeleteNodeModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ты уверен в удалении вершины?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Придется ждать пересборки графа много времени!!!</p>
                    {isNodeDeleting ? (
                        <Spinner animation="grow" />
                    ) : (
                        <Button
                            variant={"danger"}
                            onClick={() => onDeleteNode()}
                        >
                            Удалить
                        </Button>
                    )}
                </Modal.Body>
            </Modal>

            <Offcanvas
                show={showAddEdgeModal}
                onHide={() => {
                    setShowAddEdgeModal(false);
                    setSecondNode(null);
                }}
                size="lg"
            >
                {selectedNode ? (
                    <>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Добавить связь к вершине "
                                {selectedNode.data.title}"
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {isEdgeAdding ? (
                                <Spinner animation="grow" />
                            ) : (
                                <>
                                    <Row className={"mt-2 mb-3"}>
                                        <Col className="d-grid gap-2">
                                            <Button
                                                size="md"
                                                variant={
                                                    addParent
                                                        ? "primary"
                                                        : "outline-primary"
                                                }
                                                onClick={() => {
                                                    setAddParent(!addParent);
                                                    setAddChild(false);
                                                }}
                                            >
                                                + родитель
                                            </Button>
                                        </Col>
                                        <Col className="d-grid gap-2">
                                            <Button
                                                size="md"
                                                variant={
                                                    addChild
                                                        ? "primary"
                                                        : "outline-primary"
                                                }
                                                onClick={() => {
                                                    setAddParent(false);
                                                    setAddChild(!addChild);
                                                }}
                                            >
                                                + потомок
                                            </Button>
                                        </Col>
                                    </Row>
                                    {(addParent || addChild) && (
                                        <Creatable
                                            isClearable
                                            value={secondNode}
                                            onChange={(e) => {
                                                setSecondNode(e);
                                            }}
                                            onCreateOption={(inputValue) => {
                                                console.log(
                                                    "Создание вершины с заголовком: ",
                                                    inputValue
                                                );
                                                setNewNodeTitle(inputValue);
                                                setShowCreateNodeModal(true);
                                            }}
                                            options={nodesForSelect}
                                        />
                                    )}
                                    {(addParent || addChild) &&
                                    !isEdgeAdding &&
                                    secondNode ? (
                                        <Button
                                            className={"mt-2"}
                                            onClick={() => {
                                                onAddEdge();
                                            }}
                                        >
                                            Создать связь
                                        </Button>
                                    ) : (
                                        <p className="text-small text-muted">
                                            <small>
                                                Выбери из списка или кликни по
                                                вершине графа
                                            </small>
                                        </p>
                                    )}
                                </>
                            )}
                        </Offcanvas.Body>
                    </>
                ) : (
                    false
                )}
            </Offcanvas>
        </>
    );
};
