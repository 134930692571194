import { createSlice } from "@reduxjs/toolkit";

export const graphSlice = createSlice({
    name: "graph",
    initialState: {
        subjects: [],
        selectedSubjectId: null,
    },
    reducers: {
        setSubjects: (state, action) => {
            console.log("setSubjects", action);
            state.subjects = action.payload;
        },
        addNewNodeForSubject: (state, action) => {
            console.log("addNewNodeForSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.id
            );
            if (i > -1) state.subjects[i].graph.nodes.push(action.payload.node);
        },
        addNewEdgeForSubject: (state, action) => {
            console.log("addNewEdgeForSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.id
            );
            if (i > -1) state.subjects[i].graph.edges.push(action.payload.edge);
        },
        setGraphForSubject: (state, action) => {
            console.log("setGraphForSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.id
            );
            if (i > -1) state.subjects[i].graph = action.payload.graph;
            else state.subjects.push(action.payload);
        },
        setConceptsForSubject: (state, action) => {
            console.log("setConceptsForSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.id
            );
            if (i > -1) state.subjects[i].concepts = action.payload.concepts;
            else state.subjects.push(action.payload);
        },
        selectSubject: (state, action) => {
            state.selectedSubjectId = action.payload;
        },
        selectConceptForSubject: (state, action) => {
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1)
                state.subjects[i].selectedConceptId = action.payload.conceptId;
        },
        updateNodeDataforSubject: (state, action) => {
            console.log("updateNodeDataforSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1)
                state.subjects[i].graph.nodes = state.subjects[
                    i
                ].graph.nodes.map((n) => {
                    if (n.id === action.payload.nodeId) {
                        n.data = Object.assign(n.data, action.payload.body);
                    }
                    return n;
                });
        },
        updateNodeQuestionCountforSubject: (state, action) => {
            console.log("updateNodeQuestionCountforSubject", action);

            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1) {
                let k = state.subjects[i].graph.nodes.findIndex(
                    (nd) => nd.id === action.payload.nodeId
                );

                if (k > -1) {
                    let nodeData = state.subjects[i].graph.nodes[k];
                    if (
                        nodeData.questions_count !==
                        action.payload.questionsCount
                    ) {
                        state.subjects[i].graph.nodes[k].questions_count =
                            action.payload.questionsCount;
                    }
                }
            }
        },
        deleteNodeforSubject: (state, action) => {
            console.log("deleteNodeforSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1)
                state.subjects[i].graph.nodes = state.subjects[
                    i
                ].graph.nodes.filter((n) => n.id !== action.payload.nodeId);
        },
        deleteEdgeforSubject: (state, action) => {
            console.log("deleteEdgeforSubject", action);
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1)
                state.subjects[i].graph.edges = state.subjects[
                    i
                ].graph.edges.filter((n) => n.id !== action.payload.edgeId);
        },
        setThemes: (state, action) => {
            console.log("setThemes", action);
            state.subjects = state.subjects.map((subject) => {
                const themesForSubject = action.payload.filter(
                    (theme) => theme.subject.id === subject.id
                );
                subject.themes = themesForSubject;
                return subject;
            });
            const i = state.subjects.findIndex(
                (_element) => _element.id === action.payload.id
            );
            if (i > -1) state.subjects[i].themes = action.payload.themes;
        },
        setExamTree: (state, action) => {
            console.log("setExamTree", action);
            let tree = action.payload;

            state.subjects = state.subjects.map((subjectTag) => {
                subjectTag.exams = [];
                // Отобрать массив exams для предмета
                for (let exam of tree.exams) {
                    for (let subject of exam.subjects) {
                        if (subject.subject_tag_id === subjectTag.id) {
                            let examItem = {
                                exam_tag_id: exam.exam_tag_id,
                                title: exam.title,
                                nums: subject.nums,
                            };
                            subjectTag.exams.push(examItem);
                        }
                    }
                }
                return subjectTag;
            });
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setGraphForSubject,
    setConceptsForSubject,
    selectConceptForSubject,
    setSubjects,
    selectSubject,
    addNewNodeForSubject,
    updateNodeDataforSubject,
    updateNodeQuestionCountforSubject,
    deleteNodeforSubject,
    deleteEdgeforSubject,
    addNewEdgeForSubject,
    setThemes,
    setExamTree,
} = graphSlice.actions;

export default graphSlice.reducer;
