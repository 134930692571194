import React, { useEffect, useState } from "react";
import { Card, Spinner, Button, Modal } from "react-bootstrap";
import { QuestionForm } from "./QuestionForm";
import { QuestionsList } from "./QuestionsList";
import { loadQuestionsForNode } from "../../features/methodics/methodicsApi";
import { updateNodeQuestionCountforSubject } from "../../features/methodics/methodicsReducer";
import { useDispatch } from "react-redux";

export function NodeQuestionsPanel({ nodeId, subjectId }) {
    const [questions, setQuestions] = useState();
    const [editingQuestion, setEditingQuestion] = useState();

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const loadQuestions = () => {
        setIsLoading(true);
        loadQuestionsForNode(nodeId).then((data) => {
            setQuestions(data);
            dispatch(
                updateNodeQuestionCountforSubject({
                    subjectId: subjectId,
                    nodeId: nodeId,
                    questionsCount: data.length,
                })
            );
            setIsLoading(false);
        });
    };

    useEffect(() => {
        loadQuestions();
    }, [nodeId]);

    return (
        <div className={"mt-3"}>
            {!isLoading ? (
                <Card>
                    <Card.Header>
                        Список заданий для вершины {nodeId}
                    </Card.Header>
                    <Card.Body>
                        <QuestionsList
                            onClickEditButton={(editedQuestion) => {
                                console.log(
                                    `Click edit button for question:`,
                                    editedQuestion
                                );
                                setEditingQuestion(editedQuestion);
                                setShowEditModal(true);
                            }}
                            questions={questions}
                        />

                        <Button
                            variant="success"
                            onClick={() => setShowCreateModal(true)}
                            className={"mt-2"}
                        >
                            + Добавить новое
                        </Button>
                    </Card.Body>
                </Card>
            ) : (
                <div className={"mt-4"}>
                    <Spinner animation="grow" />
                </div>
            )}

            <Modal
                fullscreen
                show={showCreateModal}
                onHide={() => setShowCreateModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Создание задания</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuestionForm
                        initialNodes={[nodeId]}
                        onSubmit={() => {
                            setShowCreateModal(false);
                            loadQuestions();
                        }}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                fullscreen
                show={showEditModal}
                onHide={() => {
                    setShowEditModal(false);
                    setEditingQuestion(null);
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование задания</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuestionForm
                        question={editingQuestion}
                        initialNodes={[nodeId]}
                        onSubmit={() => {
                            setShowEditModal(false);
                            setEditingQuestion(null);
                            loadQuestions();
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}
