import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { nodeTitles } from "../../backend/constants";
import Select from "react-select";

export const NodeForm = ({
    initialConcepts,
    subject,
    initialTitle,
    onSubmit,
    node,
    initialConcept,
    isFetching,
}) => {
    const [title, setTitle] = useState(
        node ? node.data.title : initialTitle ? initialTitle : ""
    );
    const [nodeType, setNodeType] = useState(node ? node.data.type : "1");
    const [testability, setTestability] = useState(
        node ? node.data.testability : true
    );

    // для случая выбора
    const [selectedConcept, setSelectedConcept] = useState(null);
    const [concepts, setConcepts] = useState([]);

    useEffect(() => {
        if (initialConcepts) {
            let conceptsForSelectArray = [];
            for (let conceptTag of initialConcepts) {
                conceptsForSelectArray.push({
                    value: conceptTag.id,
                    label: conceptTag.concept.title,
                });
            }
            setConcepts(conceptsForSelectArray);
        }
    }, [initialConcepts]);

    useEffect(() => {
        if (concepts) {
            let queryConcept;
            // если это форма редактирования - выбираем соответствующий концепт
            if (node) {
                queryConcept = initialConcepts.find(
                    (obj) => obj.id === node.data.concept
                );
            }

            // если это добавление вершины к концепту - выбираем концепт
            if (initialConcept) {
                queryConcept = initialConcepts.find(
                    (obj) => obj.id === initialConcept.id
                );
            }

            if (queryConcept) {
                setSelectedConcept({
                    value: queryConcept.id,
                    label: queryConcept.concept.title,
                });
            }
        }
    }, [concepts, initialConcepts, initialConcept, node]);

    const nodeTypeOptions = () => {
        return nodeTitles.map((title, index) => {
            return (
                <option key={index.toString()} value={index + 1}>
                    {title}
                </option>
            );
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setTitle("");
        setConcepts([]);
        let obj = {
            title: title,
            type: nodeType,
            testability: testability,
        };

        if (selectedConcept) {
            obj.concept = selectedConcept.value;
        } else {
            obj.concept = null;
        }

        onSubmit(obj);
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Название вершины</Form.Label>
                    <Form.Control
                        required
                        rows={6}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Form.Group>
                {initialConcepts && (
                    <Form.Group className="mb-3">
                        <Form.Label>Концепт</Form.Label>
                        <Select
                            value={selectedConcept}
                            onChange={(e) => {
                                setSelectedConcept(e);
                            }}
                            options={concepts}
                            isMulti={false}
                            isClearable={true}
                        />
                    </Form.Group>
                )}
                <Form.Group className="mb-3">
                    <Form.Label>Тип задания</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setNodeType(e.target.value)}
                        value={nodeType}
                        required
                    >
                        {nodeTypeOptions()}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        checked={testability}
                        onChange={() => setTestability(!testability)}
                        type="checkbox"
                        label="Проверяемая?"
                    />
                </Form.Group>
                {!isFetching && (
                    <Button type="submit" variant={"success"}>
                        {node ? "Сохранить" : "Создать"}
                    </Button>
                )}
                {isFetching && (
                    <div className={"mt-4"}>
                        <Spinner animation="grow" />
                    </div>
                )}
            </Form>
        </>
    );
};
