import { apiClient, handleError } from "../../backend/http";
import { store } from "../../store";
import { setStats } from "../subjects/subjectsSlice";

export const getAllConceptsStats = async () => {
    console.log(
        "Начинаем загружать статистику всех концептов для пользователя"
    );
    let result = await apiClient(`stat/concepts/`)
        .then((res) => res.json())
        .then((data) => {
            console.log(
                "Успешно загружена статистика всех концептов для пользователя",
                data
            );
            store.dispatch(setStats(data));
        })
        .catch((error) => {
            handleError(error, "get concepts stats error");
        });
    return result;
};

export const getConceptStats = async (conceptTagId) => {
    console.log("Начинаем загружать статистику для концепта");
    let result = await apiClient(`stat/concept/${conceptTagId}/`)
        .then((res) => res.json())
        .then((data) => {
            console.log(
                "Успешно загружена статистика концепта для пользователя"
            );
            return data;
        })
        .catch((error) => {
            handleError(error, "get concept stats error");
        });
    return result;
};

export const getExamSubjectNumStats = async (examTagId, subjectTagId, num) => {
    let result = await apiClient(
        `stat/exam/?exam=${examTagId}&subject=${subjectTagId}&num=${num}`
    )
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            handleError(error, "get ExamSubjectNum stats error");
        });
    return result;
};

export const getExamThemeStats = async (themeId) => {
    let result = await apiClient(`stat/exam/?theme=${themeId}`)
        .then((res) => res.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            handleError(error, "get ExamTheme stats error");
        });
    return result;
};

export const getConceptGraph = async (conceptId) => {
    console.log("Начинаем загружать граф для концепта");
    let result = await apiClient(`graph/concepts/${conceptId}/user/`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружен граф для концепта", data);
            return data;
        })
        .catch((error) => {
            handleError(error, "get concept graph stats error");
        });
    return result;
};
