import React, { useEffect, useState } from "react";
import { Row, Col, Button, Breadcrumb, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getQuestion } from "../../features/questions/questionsApi";
import { QuestionForm } from "../../components/methodics/QuestionForm";

export function EditQuestion() {
    let history = useHistory();

    const [idQuery, setIdQuery] = useState();
    const [editingQuestion, setEditingQuestion] = useState();

    const onQueryClick = () => {
        console.log(`Поиск вопроса ${idQuery}`);
        getQuestion(idQuery).then((data) => {
            console.log(data);
            setEditingQuestion(data);
        });
    };

    return (
        <div>
            <Row className={"mt-3"}>
                <Breadcrumb>
                    <Breadcrumb.Item
                        href={"#"}
                        onClick={() => history.push("/")}
                    >
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={"#"}
                        onClick={() => history.push("/methodics")}
                    >
                        Интерфейс методиста
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Редактирование вопроса
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Col xs={12} className={"mb-5"}>
                    <h1>Редактирование вопроса</h1>
                    <Form.Group className="mb-3">
                        <Form.Label>Id задания</Form.Label>
                        <Form.Control
                            className={"mb-3"}
                            type="text"
                            value={idQuery}
                            onChange={(e) => setIdQuery(e.target.value)}
                            placeholder="Введите id вопроса"
                        />
                    </Form.Group>
                    {idQuery ? (
                        editingQuestion ? (
                            <Button
                                variant="danger"
                                onClick={() => {
                                    setIdQuery(null);
                                    setEditingQuestion(null);
                                }}
                            >
                                Сброс
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={onQueryClick}>
                                Загрузить вопрос
                            </Button>
                        )
                    ) : (
                        false
                    )}
                    {editingQuestion ? (
                        <Card className="mt-3">
                            <Card.Body>
                                <QuestionForm
                                    question={editingQuestion}
                                    onSubmit={() => {
                                        setIdQuery(null);
                                        setEditingQuestion(null);
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    ) : (
                        false
                    )}
                </Col>
            </Row>
        </div>
    );
}
