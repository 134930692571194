import React, { useState } from "react";
import { Form, Button, Card, Row, Col, Image } from "react-bootstrap";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setDarkMode } from "../features/settings/settingsSlice";
import { logout } from "../features/auth/authReducer";
import {
    postUserUpdates,
    postUserAvatar,
    getUser,
} from "../features/auth/authApi";
import styled from "styled-components";

const AvatarImage = styled(Image)`
    max-width: 300px;
`;

export const Profile = () => {
    const settings = useSelector((store) => store.settings);
    const user = useSelector((store) => store.auth.user);
    const dispatch = useDispatch();

    const [newAvatar, setNewAvatar] = useState();

    return (
        <Row className="my-5">
            <Col>
                <Card>
                    <Card.Body>
                        <h1>Настройки профиля</h1>
                        {user && (
                            <Formik
                                initialValues={{
                                    first_name: user.first_name,
                                    last_name: user.last_name,
                                    grade: user.grade
                                        ? user.grade.toString()
                                        : null,
                                    avatar: user.avatar,
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (newAvatar) {
                                        postUserAvatar(
                                            newAvatar.file,
                                            user
                                        ).then(() => {
                                            getUser();
                                        });
                                    }
                                    const valuesForPost = {
                                        first_name: values.first_name,
                                        last_name: values.last_name,
                                        grade: values.grade,
                                    };
                                    postUserUpdates(valuesForPost).then(
                                        (data) => {
                                            setSubmitting(false);
                                        }
                                    );
                                }}
                            >
                                {({
                                    handleChange,
                                    handleSubmit,
                                    values,
                                    isSubmitting,
                                    errors,
                                    touched,
                                }) => (
                                    <Form
                                        onSubmit={handleSubmit}
                                        validated={false}
                                        className={"mb-3"}
                                    >
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Аватар
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    {newAvatar ? (
                                                        <AvatarImage
                                                            src={newAvatar.url}
                                                            fluid
                                                            thumbnail
                                                            rounded
                                                        />
                                                    ) : user.avatar ? (
                                                        <AvatarImage
                                                            src={
                                                                "https://stagging.adaptaki.ru" +
                                                                user.avatar
                                                            }
                                                            fluid
                                                            thumbnail
                                                            rounded
                                                        />
                                                    ) : (
                                                        <AvatarImage
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                "/empty_avatar.jpg"
                                                            }
                                                            fluid
                                                            thumbnail
                                                            rounded
                                                        />
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        type="file"
                                                        required
                                                        name="avatar"
                                                        onChange={(e) => {
                                                            console.log(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                            setNewAvatar({
                                                                file: e.target
                                                                    .files[0],
                                                                url: URL.createObjectURL(
                                                                    e.target
                                                                        .files[0]
                                                                ),
                                                            });
                                                        }}
                                                        isInvalid={
                                                            !!errors.avatar
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Имя</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleChange(
                                                            "first_name"
                                                        )}
                                                        value={
                                                            values.first_name
                                                        }
                                                        autoCorrect={false}
                                                        error={
                                                            errors.first_name &&
                                                            touched.first_name &&
                                                            errors.first_name
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Фамилия
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleChange(
                                                            "last_name"
                                                        )}
                                                        value={values.last_name}
                                                        autoCorrect={false}
                                                        error={
                                                            errors.last_name &&
                                                            touched.last_name &&
                                                            errors.last_name
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Класс
                                                    </Form.Label>
                                                    <Form.Select
                                                        value={values.grade}
                                                        onChange={handleChange(
                                                            "grade"
                                                        )}
                                                    >
                                                        <option>
                                                            Не выбран..
                                                        </option>
                                                        <option value="11">
                                                            11 класс
                                                        </option>
                                                        <option value="10">
                                                            10 класс
                                                        </option>
                                                        <option value="9">
                                                            9 класс
                                                        </option>
                                                        <option value="8">
                                                            8 класс
                                                        </option>
                                                        <option value="7">
                                                            7 класс
                                                        </option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button
                                                    type="submit"
                                                    className="mb-3"
                                                    disabled={isSubmitting}
                                                >
                                                    Сохранить
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        )}
                        <hr />
                        <Button
                            title={"Выйти"}
                            onClick={() => dispatch(logout())}
                        >
                            Выйти
                        </Button>
                        <Form.Check
                            type="switch"
                            className="mt-3"
                            id="custom-switch"
                            label="Темная тема"
                            value={settings.darkMode}
                            onChange={() => {
                                dispatch(setDarkMode(!settings.darkMode));
                            }}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Profile;
