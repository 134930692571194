import { createSlice } from "@reduxjs/toolkit";

export const subjectsSlice = createSlice({
    name: "subjects",
    initialState: {
        all: [],
        stats: null,
        selectedSubjectTagId: null,
    },
    reducers: {
        setSubjects: (state, action) => {
            state.all = action.payload;
        },
        setSelectedSubjectTagId: (state, action) => {
            state.selectedSubjectTagId = action.payload;
        },
        setConceptsForSubject: (state, action) => {
            console.log("setConceptsForSubject", action);
            state.all = state.all.map((subjectTag) =>
                action.payload.subjectTagId === subjectTag.id
                    ? // transform the one with a matching id
                      { ...subjectTag, concepts: action.payload.concepts }
                    : // otherwise return original object
                      subjectTag
            );
        },
        updateConcept: (state, action) => {
            console.log("updateConcept", action);
            state.all = state.all.map((subjectTag) => {
                const i = subjectTag.concepts.findIndex(
                    (_element) => _element.id === action.payload.id
                );
                if (i > -1)
                    subjectTag.concepts[i] = Object.assign(
                        subjectTag.concepts[i],
                        action.payload
                    );
                return subjectTag;
            });
        },
        setStats: (state, action) => {
            console.log("setStats", action);
            let stats = action.payload;

            state.all = state.all.map((subjectTag) => {
                subjectTag.concepts = subjectTag.concepts.map((concept) => {
                    if (stats[concept.id]) {
                        concept.stat = stats[concept.id];
                    }
                    return concept;
                });
                return subjectTag;
            });

            console.log(state.all);
        },
        setExamTree: (state, action) => {
            console.log("setExamTree", action);
            let tree = action.payload;

            state.all = state.all.map((subjectTag) => {
                subjectTag.exams = [];
                // Отобрать массив exams для предмета
                for (let exam of tree.exams) {
                    for (let subject of exam.subjects) {
                        if (subject.subject_tag_id === subjectTag.id) {
                            let examItem = {
                                exam_tag_id: exam.exam_tag_id,
                                title: exam.title,
                                nums: subject.nums,
                            };
                            subjectTag.exams.push(examItem);
                        }
                    }
                }
                return subjectTag;
            });
        },
        setConcepts: (state, action) => {
            console.log("setConcepts", action);
            let concepts = action.payload;

            state.all = state.all.map((subjectTag) => {
                subjectTag.concepts = [];
                // Отобрать массив концептов для предмета
                for (let concept of concepts) {
                    if (concept.subject.id === subjectTag.id) {
                        subjectTag.concepts.push(concept);
                    }
                }
                return subjectTag;
            });
        },
        selectConceptForSubject: (state, action) => {
            console.log("selectConceptForSubject", action);
            const i = state.all.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1)
                state.all[i].selectedConceptId = action.payload.conceptId;
        },
        selectExamForSubject: (state, action) => {
            console.log("selectExamForSubject", action);
            const i = state.all.findIndex(
                (_element) => _element.id === action.payload.subjectId
            );
            if (i > -1) state.all[i].selectedExamId = action.payload.examId;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setSubjects,
    setConceptsForSubject,
    setStats,
    updateConcept,
    setSelectedSubjectTagId,
    setExamTree,
    setConcepts,
    selectConceptForSubject,
    selectExamForSubject,
} = subjectsSlice.actions;

export default subjectsSlice.reducer;
