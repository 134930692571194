import { Card, Image } from "react-bootstrap";
import React from "react";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import uuid from "react-uuid";

const AnswerComponent = ({ question }) => {
    if (question.question_type === "ChoiceQuestion") {
        const optionsList = question.options_data.map((option) => {
            return (
                <li
                    key={option.id.toString()}
                    className={option.is_true ? "text-success" : "text-danger"}
                >
                    <ReactMarkdown
                        remarkPlugins={[remarkMath, gfm]}
                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                        children={option.option_text}
                    />
                    {option.option_image && (
                        <Image
                            src={option.option_image.replace(
                                "/media",
                                "https://stagging.adaptaki.ru/media"
                            )}
                            rounded
                            className={"mb-3"}
                            style={{ maxWidth: 300 }}
                        />
                    )}
                </li>
            );
        });

        return (
            <>
                <p>
                    <b>Ответы:</b>
                </p>
                <ul>{optionsList}</ul>
            </>
        );
    } else if (question.question_type === "StringAnswerQuestion") {
        let array = question.right_answer.split(";");
        if (array.length > 1) {
            const ansList = array.map((ans) => {
                return <li key={ans.toString()}>{ans}</li>;
            });
            return (
                <>
                    <b>Ответы:</b>
                    {ansList}
                </>
            );
        } else {
            return <b>Ответ: {question.right_answer}</b>;
        }
    } else {
        return <b>Ответ: {question.right_answer}</b>;
    }
};

const QuestionPane = ({ question, onClickEditButton }) => {
    // let content = question.question_text.replace('/media', 'https://stagging.adaptaki.ru/media')

    return (
        <Card key={question.id.toString()} className={"mt-2"}>
            <Card.Body>
                <Card.Text>
                    <b>Задание #{question.id}</b>{" "}
                    <span
                        className={"btn-link"}
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickEditButton(question)}
                    >
                        Ред.
                    </span>
                </Card.Text>
                <Card.Text>
                    <ReactMarkdown
                        remarkPlugins={[remarkMath, gfm]}
                        rehypePlugins={[rehypeKatex, rehypeRaw]}
                        children={question.question_text}
                    />
                    {question.image && (
                        <Card.Text>
                            <Image
                                src={question.image.replace(
                                    "/media",
                                    "https://stagging.adaptaki.ru/media"
                                )}
                                rounded
                                className={"mw-100"}
                            />
                        </Card.Text>
                    )}
                    <AnswerComponent question={question} />
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export const QuestionsList = ({ questions, onClickEditButton }) => {
    if (questions) {
        return questions.map((question) => {
            return (
                <QuestionPane
                    key={uuid()}
                    question={question}
                    onClickEditButton={onClickEditButton}
                />
            );
        });
    } else {
        return false;
    }
};
