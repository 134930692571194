import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Button, Stack, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUserStats } from "../../features/auth/authApi";
import { levelTitles } from "../../features/stats/levelTitles";
import AProgressBar from "../../components/particles/AProgressBar";
import { setSelectedSubjectTagId } from "../../features/subjects/subjectsSlice";
import Subject from "./Subject";
import { useHistory } from "react-router-dom";
import { getAllConceptsStats } from "../../features/stats/statsApi";

export function Index() {
    const user = useSelector((store) => store.auth.user);

    const subjectTags = useSelector((state) => state.subjects.all);
    const selectedSubjectTagId = useSelector(
        (state) => state.subjects.selectedSubjectTagId
    );

    const [userLevel, setUserLevel] = useState();
    const [progressScore, setProgressScore] = useState();
    const [isStatsLoading, setIsStatsLoading] = useState(false);

    const history = useHistory();

    const dispatch = useDispatch();

    const loadStats = () => {
        setIsStatsLoading(true);
        getUserStats().then((totalSolved) => {
            let solved_level = Math.ceil((totalSolved + 1) / 30) - 1;
            setUserLevel(solved_level);

            let progress_score = totalSolved - solved_level * 30;
            setProgressScore(progress_score);
            setIsStatsLoading(false);
        });
    };

    useEffect(() => {
        loadStats();
        getAllConceptsStats();
    }, []);

    return (
        <div>
            <Row className="mt-3">
                <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 8 }}>
                    <Stack direction="horizontal" gap={3} className={"mb-3"}>
                        {subjectTags.map((subjectTag) => (
                            <Button
                                variant={
                                    subjectTag.id === selectedSubjectTagId
                                        ? "primary"
                                        : "outline-secondary"
                                }
                                key={subjectTag.id}
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                    history.push("/");
                                    dispatch(
                                        setSelectedSubjectTagId(
                                            subjectTag.id ===
                                                selectedSubjectTagId
                                                ? null
                                                : subjectTag.id
                                        )
                                    );
                                }}
                            >
                                {subjectTag.title}
                            </Button>
                        ))}
                    </Stack>
                    {selectedSubjectTagId ? (
                        <Subject subjectTagId={selectedSubjectTagId} />
                    ) : (
                        <Card border="warning">
                            <Card.Body>
                                <Card.Text>Выбери предмет</Card.Text>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
                <Col xs={{ order: 1, span: 12 }} md={{ order: 2, span: 4 }}>
                    <Card className="mb-5">
                        <Card.Body>
                            <h4 as={Link} to="/profile">
                                <Link to="/profile">
                                    Привет,{" "}
                                    {user.first_name
                                        ? user.first_name
                                        : user.email}
                                </Link>
                            </h4>
                            <p>
                                Твой уровень:{" "}
                                {userLevel && userLevel < levelTitles.length
                                    ? levelTitles[userLevel]
                                    : levelTitles[levelTitles.length - 1]}{" "}
                                {userLevel && `(${userLevel + 1})`}
                            </p>
                            {!isStatsLoading ? (
                                <AProgressBar
                                    value={((progressScore + 3) / 33) * 100}
                                    variant={"main"}
                                />
                            ) : (
                                <Spinner animation="grow" />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
