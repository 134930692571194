import React from "react";

import * as Sentry from "@sentry/react";

import { AppWithStore } from "./AppWithStore";
import { AppWithTheme } from "./AppWithTheme";
import { AppNavigation } from "./AppNavigation";

Sentry.init({
    dsn: "https://eb7bc049b652485c88ba25e4fd53f870@o1048572.ingest.sentry.io/6108043",
    debug: true,
});

function App() {
    return (
        <AppWithStore>
            <AppWithTheme>
                <AppNavigation />
            </AppWithTheme>
        </AppWithStore>
    );
}

export default App;
