export const findConceptById = (state, conceptTagId) => {
    let subjectTags = state.subjects.all;
    for (let subject of subjectTags) {
        if (subject.concepts.length > 0) {
            const i = subject.concepts.findIndex(
                (_element) => _element.id === conceptTagId
            );
            if (i > -1) {
                return subject.concepts[i];
            }
        }
    }
    return null;
};

export const findSelectedSubject = (state) => {
    const subjectTags = state.subjects.all;
    const selectedSubjectTagId = state.subjects.selectedSubjectTagId;
    const selectedSubject = subjectTags.find(
        (obj) => obj.id === selectedSubjectTagId
    );
    return selectedSubject;
};

export const findSelectedExamById = (state, subjectTagId, selectedExamId) => {
    let subjectTags = state.subjects.all;
    const i = subjectTags.findIndex((_element) => _element.id === subjectTagId);
    if (i > -1) {
        if (subjectTags[i].exams.length > 0) {
            const k = subjectTags[i].exams.findIndex(
                (_element) => _element.exam_tag_id === selectedExamId
            );
            if (k > -1) {
                return subjectTags[i].exams[k];
            }
        }
    }
    return null;
};
