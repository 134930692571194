import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavbarTop = () => {
    const user = useSelector((store) => store.auth.user);
    const token = useSelector((store) => store.auth.token);

    return (
        <Navbar bg="light" expand={"lg"}>
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <img
                        height="30"
                        className="d-inline-block align-top"
                        src={process.env.PUBLIC_URL + "/LogoGray.png"}
                        alt=""
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {user.role ? (
                            <Nav.Link as={Link} to="/methodics">
                                Интерфейс методиста
                            </Nav.Link>
                        ) : (
                            false
                        )}
                        {user.role === 1 ? (
                            <Nav.Link
                                href="https://stagging.adaptaki.ru/admin"
                                target={"_blank"}
                            >
                                Админка Stagging
                            </Nav.Link>
                        ) : (
                            false
                        )}
                        {user.role === 1 ? (
                            <Nav.Link
                                href="https://stagging.adaptaki.ru/redoc"
                                target={"_blank"}
                            >
                                API Docs
                            </Nav.Link>
                        ) : (
                            false
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarTop;
