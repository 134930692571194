import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { apiClient } from "../../backend/http";

export const ConceptForm = ({ onSubmit, concept, subject }) => {
    const [title, setTitle] = useState(concept ? concept.concept.title : "");
    const [checked, setChecked] = useState(concept ? concept.is_active : false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (concept) {
            // РЕдактирование is_active
            await apiClient(
                `tags/concept/${concept.id}/`,
                {
                    is_active: checked,
                },
                "PUT"
            ).then((res) => console.log(res));

            await apiClient(
                `concepts/${concept.concept.id}/`,
                {
                    title: title,
                },
                "PUT"
            ).then((res) => console.log(res));

            onSubmit({
                title: title,
                is_active: checked,
            });
        } else {
            // Создание
            await apiClient(
                `tags/concept/`,
                {
                    subject: subject.id,
                    title: title,
                },
                "POST"
            ).then((res) => onSubmit());
        }

        setTitle("");
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Название концепта</Form.Label>
                    <Form.Control
                        required
                        rows={6}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Form.Group>
                {concept ? (
                    <Form.Group className="mb-3">
                        <Form.Check
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            type="checkbox"
                            label="Активный?"
                        />
                    </Form.Group>
                ) : (
                    false
                )}
                <Button type="submit" variant={"success"}>
                    {concept ? "Сохранить" : "Создать"}
                </Button>
            </Form>
        </>
    );
};
