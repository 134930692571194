import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Spinner, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { finishUtr, getUtr } from "../features/tasks/tasksApi";
import { useHistory } from "react-router-dom";
import Question from "../components/question/Question";

const Task = () => {
    let { utrId } = useParams();

    const history = useHistory();

    const [utr, setUtr] = useState();

    const currentQuestionId = utr
        ? utr.task.questions[utr.current_question_idx]
        : null;

    useEffect(() => {
        getUtr(utrId).then((data) => {
            setUtr(data);
        });
    }, [utrId]);

    return (
        <Row className={"mt-3"}>
            <Col>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                {utr ? (
                                    <>
                                        {utr.task.task_type ===
                                        "ConceptTask" ? (
                                            <Breadcrumb>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.concept_tag
                                                            .subject.id
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.concept_tag
                                                            .concept.title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {`Работа #${utr.id}`}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        ) : (
                                            false
                                        )}
                                        {utr.task.task_type === "NumTask" ? (
                                            <Breadcrumb>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.subject.subject
                                                            .title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    Тренажер{" "}
                                                    {
                                                        utr.task.exam_tag.exam
                                                            .title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    Задание #{utr.task.num}
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {`Работа #${utr.id}`}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        ) : (
                                            false
                                        )}
                                        {utr.task.task_type === "ThemeTask" ? (
                                            <Breadcrumb>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.subject.subject
                                                            .title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.theme_tag.title
                                                            .title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {
                                                        utr.task.theme_tag.theme
                                                            .title
                                                    }
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item active>
                                                    {`Работа #${utr.id}`}
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        ) : (
                                            false
                                        )}
                                        <Button
                                            variant={"secondary"}
                                            className={"mb-3"}
                                            onClick={() => {
                                                history.push("/");
                                            }}
                                        >
                                            ◀︎ Назад
                                        </Button>
                                        {currentQuestionId !== -1 ? (
                                            <Question
                                                questionId={currentQuestionId}
                                                utrId={utrId}
                                                onNextQuestion={() => {
                                                    getUtr(utrId).then(
                                                        (data) => {
                                                            setUtr(data);
                                                        }
                                                    );
                                                }}
                                                onFinish={() => {
                                                    finishUtr(utrId).then(
                                                        () => {
                                                            history.push("/");
                                                        }
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <p>Вопросы кончились</p>
                                        )}
                                        {/* <p className={"text-muted"}>
                                            <pre>
                                                Utr{" "}
                                                {JSON.stringify(utr, null, 2)}
                                            </pre>
                                        </p> */}
                                    </>
                                ) : (
                                    <Spinner animation="grow" />
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Task;
