import React from "react";
import { Button, Card, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { regUser } from "../features/auth/authApi";
import { useHistory } from "react-router-dom";

export function Reg() {
    let history = useHistory();

    return (
        <Row className="mt-5">
            <Col xs={{ span: 6, offset: 3 }}>
                <Card>
                    <Card.Body>
                        <h1>Регистрация через email</h1>
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validate={(values) => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = "Введите Email";
                                } else if (
                                    !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i.test(
                                        values.email
                                    )
                                ) {
                                    errors.email = "Некорректный email";
                                }
                                return errors;
                            }}
                            onSubmit={(
                                values,
                                { setSubmitting, setFieldError }
                            ) => {
                                regUser(values).then((data) => {
                                    if (data.error) {
                                        console.log(data.error);
                                        setFieldError(
                                            "email",
                                            data.error.message
                                        );
                                    } else {
                                        history.push("/");
                                    }
                                    setSubmitting(false);
                                });
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <Form onSubmit={handleSubmit} validated={false}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            isInvalid={
                                                errors.email && touched.email
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Пароль</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            isInvalid={
                                                errors.password &&
                                                touched.password
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Зарегистрироваться
                                    </Button>
                                    <span className="mx-3">или</span>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => history.push("/")}
                                    >
                                        Войти
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
