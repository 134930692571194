import { apiClient, handleError } from "../../backend/http";
import { store } from "../../store";
import {
    setConceptsForSubject,
    setExamTree,
    setSubjects,
    updateConcept,
    setConcepts,
} from "./subjectsSlice";

export const getExamTree = async () => {
    console.log("Начинаем загружать дерево экзаменов");
    await apiClient("tags/tree/")
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружено дерево экзаменов");
            store.dispatch(setExamTree(data));
        })
        .catch((error) => {
            handleError(error, "fetch exam tree error");
        });
};

export const getConcepts = async () => {
    console.log("Начинаем загружать список концептов");
    let result = await apiClient(`tags/concept/active/`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружен список концептов");
            store.dispatch(setConcepts(data));
            return data;
        })
        .catch((error) => {
            handleError(error, "fetch concepts error");
        });
    return result;
};

export const getConceptsBySubjectTagId = async (subjectTagId) => {
    console.log("Начинаем загружать список концептов для предмета");
    let result = await apiClient(`tags/concept/active/?subject=${subjectTagId}`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружен список концептов для предмета");
            store.dispatch(
                setConceptsForSubject({ subjectTagId, concepts: data })
            );
            return data;
        })
        .catch((error) => {
            handleError(error, "fetch concepts for subject error");
        });
    return result;
};

export const getConceptById = async (conceptTagId) => {
    let result = await apiClient(`tags/concept/${conceptTagId}/`)
        .then((res) => res.json())
        .then((data) => {
            store.dispatch(updateConcept(data));
            return data;
        })
        .catch((error) => {
            handleError(error, "fetch concept error");
        });
    return result;
};

export const getSubjects = async () => {
    let result = await apiClient("tags/subjects/")
        .then((res) => res.json())
        .then((data) => {
            let subjectTags = data.map((sTag) => {
                return {
                    id: sTag.id,
                    title: sTag.subject.title,
                    subject: sTag.subject,
                };
            });
            store.dispatch(setSubjects(subjectTags));
        })
        .catch((error) => {
            handleError(error, "get subjects error");
        });
    return result;
};
