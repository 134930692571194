import { handleError, apiClient } from "../../backend/http";

export const getQuestion = async (questionId) => {
    console.log("Начинаем загружать вопрос", questionId);
    let result = await apiClient(`questions/${questionId}/?answer=True`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешная загрузка вопроса", questionId);
            return data;
        })
        .catch((error) => {
            handleError(error, "get question error");
        });
    return result;
};

export const postQuestionAnswer = async (utrId, questionId, answer) => {
    console.log("Отправляем ответ на сервер", answer);
    let result = await apiClient(`utrs/${utrId}/questions/${questionId}/`, {
        answer: answer,
    })
        .then((res) => res.json())
        .then((data) => {
            console.log("Сервер ответил на попытку ответа", data);
            return data;
        })
        .catch((error) => {
            handleError(error, "post question answer error");
        });
    return result;
};
