import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Spinner, Card, Row, Col } from "react-bootstrap";

// Routing
import { HashRouter, Switch, Route } from "react-router-dom";

import { Login } from "./screens/Login";
import { Reg } from "./screens/Reg";
import { Profile } from "./screens/Profile";
import { Index } from "./screens/index/Index";
import { TrainerCreation } from "./screens/methodics/TrainerCreation";
import { GraphCreation } from "./screens/methodics/GraphCreation";
import { MethodicsIndex } from "./screens/methodics/MethodicsIndex";
import { getUser, checkAuth } from "./features/auth/authApi";
import {
    getSubjects,
    getExamTree,
    getConcepts,
} from "./features/subjects/subjectsApi";
import NavbarTop from "./components/NavbarTop";
import Task from "./screens/Task";
import { logout } from "./features/auth/authReducer";
import { EditQuestion } from "./screens/methodics/EditQuestion";

export const AppNavigation = () => {
    const token = useSelector((store) => store.auth.token);
    const user = useSelector((store) => store.auth.user);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();

    // Check authentication on load
    useEffect(() => {
        if (token && !user) {
            getUser();
        }
    }, [token, user]);

    // Get exam tree and subjects on init or token change
    useEffect(() => {
        setIsFetching(true);
        if (token) {
            checkAuth().then((isAuth) => {
                if (isAuth) {
                    let promises = [
                        getExamTree(),
                        getSubjects(),
                        getConcepts(),
                    ];
                    if (!user.email) {
                        promises.push(getUser());
                    }
                    Promise.all(promises).then(() => {
                        setIsFetching(false);
                    });
                }
            });
        } else {
            setIsFetching(false);
            dispatch(logout());
        }
    }, [token]);

    return (
        <HashRouter>
            <NavbarTop />
            <Container fluid>
                {!token && (
                    <Switch>
                        <Route exact path="/reg">
                            <Reg></Reg>
                        </Route>
                        <Route>
                            <Login path="/" />
                        </Route>
                    </Switch>
                )}
                {token && !isFetching && (
                    <Switch>
                        <Route exact path="/">
                            <Index />
                        </Route>
                        <Route exact path="/profile">
                            <Profile />
                        </Route>
                        <Route exact path="/task/:utrId">
                            <Task />
                        </Route>
                        <Route exact path="/methodics">
                            <MethodicsIndex />
                        </Route>
                        <Route exact path="/methodics/graph">
                            <GraphCreation />
                        </Route>
                        <Route exact path="/methodics/trainer">
                            <TrainerCreation />
                        </Route>
                        <Route exact path="/methodics/editquestion">
                            <EditQuestion />
                        </Route>
                    </Switch>
                )}
                {token && isFetching && (
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs={4}>
                                <Card className="mt-5">
                                    <Card.Body>
                                        <Spinner animation="grow" />
                                        <Card.Text>
                                            Загрузка данных...
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </Container>
        </HashRouter>
    );
};
