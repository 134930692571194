import React, { useEffect, useState } from "react";
import { Button, Card, Spinner, Row, Col, Alert } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { findConceptById } from "../../features/subjects/subjectsSelectors";
import { getConceptById } from "../../features/subjects/subjectsApi";
import {
    getConceptStats,
    getConceptGraph,
} from "../../features/stats/statsApi";
import { getConceptUtr } from "../../features/tasks/tasksApi";
import { useHistory } from "react-router-dom";
import Graph from "react-graph-vis";

const graphOptions = {
    edges: {
        smooth: {
            type: "cubicBezier",
            forceDirection: "horizontal",
            roundness: 0.4,
        },
        arrows: "middle",
        color: {
            inherit: false,
            opacity: 0.5,
            highlight: "red",
        },
    },
    nodes: {
        shape: "dot",
        scaling: {
            min: 5,
            max: 40,
        },
        widthConstraint: 160,
        color: {
            highlight: "red",
        },
    },
    height: "100%",
    interaction: { hover: true },
    autoResize: true,
    layout: {
        improvedLayout: true,
        hierarchical: {
            direction: "UD", // Works fine
            sortMethod: "directed", // Works fine, hubsize destroy directions
            shakeTowards: "leaves",
            parentCentralization: true,

            levelSeparation: 200, // The distance between the different levels.
            treeSpacing: 30, // Distance between different trees (independent networks)
            nodeSpacing: 120, // If you enable physics, the node distance there will be the effective node distance.
        },
    },
    physics: {
        enabled: true,
        hierarchicalRepulsion: {
            centralGravity: 0.0,
            springLength: 100,
            springConstant: 0.01,
            nodeDistance: 220,
            damping: 0.09,
            avoidOverlap: 0,
        },
    },
};

export const calculateGraphVisData = (graph) => {
    let calculatedNodes = graph.nodes.map((node) => {
        let newNode = {
            id: node.id,
            title: node.data.title,
            label: node.data.title,
            data: node.data,
            borderWidth: 2,
        };

        let nodeBackgroundColor = "#eeeeee";
        if (node.data.prob_s < 0.3 && node.data.prob_s > 0.05) {
            nodeBackgroundColor = "#E37961";
        } else if (node.data.prob_s < 0.5) {
            nodeBackgroundColor = "#F3C847";
        } else if (node.data.prob_s < 0.8) {
            nodeBackgroundColor = "#FDF368";
        } else {
            nodeBackgroundColor = "#9ECE62";
        }

        newNode.color = {
            background: nodeBackgroundColor,
        };

        newNode.shape = "box";

        return newNode;
    });

    return {
        nodes: calculatedNodes,
        edges: graph.links,
    };
};

// Собираем кнопку в зависимости от того решал или не решал, броса или не бросал пользователь тестирование
const ConceptButtons = ({ concept, onSolveClick }) => {
    // Если концепт не пройден в режиме диагностики, принимаем решение дальше
    if (!concept.is_solved) {
        // Если полученный utr указывает на первый вопрос, то значит тестирование еще не начиналось
        if (concept.first_time) {
            return (
                <>
                    <p>Тема еще не решалась</p>
                    <Button onClick={onSolveClick}>Начать диагностику</Button>
                </>
            );
        } else {
            return (
                <>
                    <p>Первая диагностика не завершена</p>
                    <Button onClick={onSolveClick}>
                        Продолжить диагностику
                    </Button>
                </>
            );
        }
    } else {
        // Если концепт пройден в режиме диагностики, принимаем решение дальше
        // Если прилетел массив с -1, то все задачи вырешаны и можно показать только результаты
        if (concept.questions_are_over) {
            return <p>Решены все задачи в теме</p>;
        } else {
            return (
                <>
                    <p>Можно продолжить отработку</p>
                    <Button onClick={onSolveClick}>
                        Продолжить тестирование
                    </Button>
                </>
            );
        }
    }
};

const Concept = ({ conceptTagId }) => {
    const concept = useSelector((state) =>
        findConceptById(state, parseInt(conceptTagId))
    );

    const [isUtrLoading, setIsUtrLoading] = useState(false);

    const [conceptStats, setConceptStats] = useState();

    const [network, setNetwork] = useState();
    const [graph, setGraph] = useState();
    const [conceptNodes, setConceptNodes] = useState([]);

    const history = useHistory();

    useEffect(() => {
        setConceptStats(null);
        getConceptById(conceptTagId).then((concept) => {
            if (concept.is_solved) {
                getConceptStats(conceptTagId).then((data) => {
                    setConceptStats(data);
                });

                getConceptGraph(conceptTagId).then((data) => {
                    setConceptNodes(data.nodes);
                    setGraph(calculateGraphVisData(data));
                });
            }
        });
    }, [conceptTagId]);

    useEffect(() => {
        if (graph && network) {
            network.setData(graph);
        }
    }, [network, graph]);

    const onSolveClick = () => {
        setIsUtrLoading(true);
        getConceptUtr(conceptTagId).then((utr) => {
            setIsUtrLoading(false);
            history.push(`/task/${utr.id}`);
        });
    };

    return (
        <div>
            <h1 className="mb-3">{concept.concept.title}</h1>
            {!isUtrLoading ? (
                <ConceptButtons concept={concept} onSolveClick={onSolveClick} />
            ) : (
                <Card>
                    <Card.Body>
                        <Card.Text>Подбираем оптимальные задания</Card.Text>
                        <Spinner animation="grow" />
                    </Card.Body>
                </Card>
            )}
            {conceptStats ? (
                <>
                    <Row>
                        <Col>
                            <Alert variant={"info"}>
                                Общий балл
                                <h1>
                                    {conceptStats.score} из {conceptStats.total}
                                </h1>
                            </Alert>
                            <Alert variant={"info"}>
                                Освоение темы
                                <h1>{conceptStats.percent}%</h1>
                            </Alert>
                        </Col>
                        <Col>
                            <p>Хорошо освоено</p>
                            {conceptStats.best.map((node) => (
                                <Alert key={node.id} variant={"success"}>
                                    {node.title}
                                </Alert>
                            ))}
                        </Col>
                        <Col>
                            <p>Требует повторения</p>
                            {conceptStats.worst.map((node) => (
                                <Alert key={node.id} variant={"danger"}>
                                    {node.title}
                                </Alert>
                            ))}
                        </Col>
                    </Row>
                    {concept.is_solved && graph ? (
                        <>
                            <p>Список навыков</p>
                            <Row>
                                <Col>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <td>
                                                    <b>Название навыка</b>
                                                </td>
                                                <td>
                                                    <b>Освоение</b>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {conceptNodes
                                                .sort(
                                                    (a, b) =>
                                                        parseFloat(
                                                            b.data.prob_s
                                                        ) -
                                                        parseFloat(
                                                            a.data.prob_s
                                                        )
                                                )
                                                .map((node) => (
                                                    <tr
                                                        className={
                                                            node.data.prob_s <
                                                            0.5
                                                                ? "table-danger"
                                                                : node.data
                                                                      .prob_s <
                                                                  0.8
                                                                ? "table-warning"
                                                                : "table-success"
                                                        }
                                                    >
                                                        <td>
                                                            {node.data.title}
                                                        </td>
                                                        <td>
                                                            {node.data.prob_s *
                                                                100}{" "}
                                                            %
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <p>Карта знаний</p>
                            <Row>
                                <Col>
                                    <Card style={{ height: 550 }}>
                                        <Graph
                                            graph={{ nodes: [], edges: [] }}
                                            options={graphOptions}
                                            getNetwork={(network) =>
                                                setNetwork(network)
                                            }
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <p>Карта знаний</p>
                            <Spinner animation="grow" />
                        </>
                    )}
                </>
            ) : (
                false
            )}
        </div>
    );
};

export default Concept;
