import fetchClient, { apiClient, handleError } from "../../backend/http";

export const getUtr = async (utrId) => {
    let result = await apiClient(`utrs/${utrId}/`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешное получение utr", data);
            return data;
        })
        .catch((error) => {
            handleError(error, "get utr error");
        });
    return result;
};

// TODO: добавить сериализатор exam_tag
export const getThemeUtr = async (themeId) => {
    let result = await apiClient(`theme_task/${themeId}/`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => {
            handleError(error, "get theme utr error");
        });
    return result;
};

export const getExamSubjectNumUtr = async (examTagId, subjectTagId, num) => {
    let result = await apiClient(`num_task/`, {
        subject: subjectTagId,
        exam: examTagId,
        num: num,
    })
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => {
            handleError(error, "get exam subject num utr error");
        });
    return result;
};

export const getExamSubjectUtr = async (examTagId, subjectTagId) => {
    let result = await fetchClient
        .get(`stat/exam/?exam=${examTagId}&subject=${subjectTagId}`)
        .then((res) => res.data)
        .catch((error) => {
            handleError(error, "get exam subject utr error");
        });
    return result;
};

export const getConceptUtr = async (conceptTagId) => {
    console.log("Start getting utr for concept", conceptTagId);
    let result = await apiClient(`concept_task/${conceptTagId}/`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Successful get utr for concept", conceptTagId);
            return data;
        })
        .catch((error) => {
            handleError(error, "get concept utr error");
        });
    return result;
};

export const finishUtr = async (utrId) => {
    let result = await apiClient(`utrs/${utrId}/finish/`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((error) => {
            handleError(error, "finish utr error");
        });
    return result;
};
