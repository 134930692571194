// React
import React from "react";

// Styling
import styled from "styled-components";

const ProgressContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    height: ${(props) => props.height}px;
    border-radius: ${(props) => props.height / 2}px;
    background-color: ${(props) =>
        props.theme.isDark
            ? props.theme.const.Colors.grayAccent
            : props.theme.const.Colors.brightGrayAccent};
`;

const ProgressInner = styled.div`
    color: ${(props) => props.theme.colors.textColor};
    font-size: ${(props) => props.theme.const.Fonts.sizes.input};
    height: ${(props) => props.height}px;
    width: ${(props) => props.percent}%;
    border-radius: ${(props) => props.height / 2}px;
    background-color: ${(props) => {
        if (props.variant === "default") {
            if (props.percent > 80) {
                return props.theme.const.Colors.greenMessage;
            } else if (props.percent > 50) {
                return props.theme.const.Colors.yellowMessage;
            } else {
                return props.theme.const.Colors.redMessage;
            }
        } else {
            return props.theme.const.Colors.greenMessage;
        }
    }};
`;

const AProgressBar = ({
    height = 16,
    minValue = 0,
    maxValue = 100,
    value = 0,
    variant = "default",
}) => {
    const percent = ((value - minValue) / (maxValue - minValue)) * 100;

    return (
        <ProgressContainer height={height}>
            <ProgressInner
                height={height}
                percent={percent}
                variant={variant}
            />
        </ProgressContainer>
    );
};

export default AProgressBar;
