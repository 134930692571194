import React from "react";
import { useState } from "react";
import { Card, Form, ListGroup, Spinner, Button, Modal } from "react-bootstrap";
import { ConceptForm } from "../../../components/methodics/ConceptForm";

export const Concepts = ({
    isSubjectConceptsLoading,
    loadConcepts,
    showGrayNodes,
    setShowGrayNodes,
    onConceptSelect,
    subjectConcepts,
    selectedConcept,
    selectedSubject,
    focusMode,
    user,
}) => {
    const [conceptQuery, setConceptQuery] = useState();

    const [showCreateConceptModal, setShowCreateConceptModal] = useState(false);
    const [showEditConceptModal, setShowEditConceptModal] = useState(false);

    const conceptsList = (conceptQuery) => {
        if (isSubjectConceptsLoading) {
            return (
                <div className={"mt-4"}>
                    <Spinner animation="grow" />
                </div>
            );
        } else {
            let conceptsFiltered = subjectConcepts;
            if (conceptQuery) {
                conceptsFiltered = conceptsFiltered.filter((c) => {
                    return (
                        c.concept.title
                            .toLowerCase()
                            .indexOf(conceptQuery.toLowerCase()) > -1
                    );
                });
            }

            let conceptsList = conceptsFiltered.map((conceptTag) => {
                return (
                    <ListGroup.Item
                        action
                        variant={conceptTag.is_active ? "" : "danger"}
                        onClick={() => {
                            setConceptQuery(null);
                            onConceptSelect(conceptTag.id);
                        }}
                        key={conceptTag.id.toString()}
                    >
                        {conceptTag.concept.title}{" "}
                        {!conceptTag.is_active && "НЕАКТИВНЫЙ"}
                    </ListGroup.Item>
                );
            });

            return conceptsList;
        }
    };

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    {selectedConcept ? (
                        <>
                            <Button
                                variant="outline-primary"
                                className={"btn-sm mb-3"}
                                onClick={() => onConceptSelect(null)}
                            >
                                Назад к полному графу
                            </Button>
                            <h4>{selectedConcept.concept.title}</h4>
                            {user.role && user.role < 3 ? (
                                <Card.Text>
                                    <Button
                                        variant="outline-primary"
                                        className={"btn-sm"}
                                        onClick={() =>
                                            setShowEditConceptModal(true)
                                        }
                                    >
                                        Редактировать
                                    </Button>
                                </Card.Text>
                            ) : (
                                false
                            )}
                            {!focusMode ? (
                                <Form.Group>
                                    <Form.Check
                                        className={"mt-2"}
                                        type="checkbox"
                                        label="Показать серые вершины"
                                        value={showGrayNodes}
                                        onChange={() => {
                                            setShowGrayNodes(!showGrayNodes);
                                        }}
                                    />
                                </Form.Group>
                            ) : (
                                false
                            )}
                        </>
                    ) : (
                        <>
                            <p>Выбери концепт из списка</p>
                            <Form.Control
                                className={"mb-3"}
                                type="text"
                                onChange={(e) =>
                                    setConceptQuery(e.target.value)
                                }
                                placeholder="Поиск концепта"
                            />
                            <ListGroup
                                className={"mb-3"}
                                style={{ maxHeight: 200, overflowY: "scroll" }}
                            >
                                {user.role && user.role < 3 ? (
                                    <ListGroup.Item
                                        action
                                        variant={"success"}
                                        onClick={() =>
                                            setShowCreateConceptModal(true)
                                        }
                                    >
                                        + создать новый
                                    </ListGroup.Item>
                                ) : (
                                    false
                                )}
                                {conceptsList(conceptQuery)}
                            </ListGroup>
                        </>
                    )}
                </Card.Body>
            </Card>

            <Modal
                show={showCreateConceptModal}
                onHide={() => setShowCreateConceptModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Создание концепта</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConceptForm
                        subject={selectedSubject}
                        onSubmit={() => {
                            console.log("Концепт успешно создан");
                            setShowCreateConceptModal(false);
                            loadConcepts();
                        }}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showEditConceptModal}
                onHide={() => setShowEditConceptModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование концепта</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConceptForm
                        concept={selectedConcept}
                        onSubmit={(concept) => {
                            console.log("Концепт успешно отредактирован");
                            loadConcepts();
                            setShowEditConceptModal(false);
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
