import { apiClient } from "../../backend/http";
import { store } from "../../store";
import {
    setConceptsForSubject,
    setGraphForSubject,
    setSubjects,
    setThemes,
    setExamTree,
} from "./methodicsReducer";

// Загружаем весь граф по предмету
export const loadSubjectGraph = async (subjectTagId) => {
    const graphUrl = `graph/subjects/${subjectTagId}/?features=True`;
    console.log("Начало загрузки всего графа по предмету", subjectTagId);
    let result = await apiClient(graphUrl)
        .then(async (res) => {
            let data = await res.json();
            let graphData = {
                nodes: data.nodes,
                edges: data.links,
            };
            console.log("Успешная загрузка графа по предмету", subjectTagId);
            store.dispatch(
                setGraphForSubject({
                    id: subjectTagId,
                    graph: graphData,
                })
            );
            return graphData;
        })
        .catch((error) => {
            console.error("Ошибка при загрузке всего графа по предмету", error);
            return false;
        });

    return result;
};

// Загружаем все концепты
export const loadConceptsForSubject = async (subjectTagId) => {
    console.log("Начинаем загружать все концепты по предмету", subjectTagId);
    let result = await apiClient(`tags/concept/?subject=${subjectTagId}`)
        .then((res) => res.json())
        .then((data) => {
            console.log(
                "Успешная загрузка концептов по предмету",
                subjectTagId
            );
            store.dispatch(
                setConceptsForSubject({ id: subjectTagId, concepts: data })
            );
            return data;
        })
        .catch((error) => {
            console.error(
                "Ошибка при загрузке всех концептов по предмету",
                error
            );
            return false;
        });
    return result;
};

export const loadSubjects = async () => {
    console.log("Начинаем загружать все предметы");

    let result = await apiClient("tags/subjects/")
        .then((res) => res.json())
        .then((data) => {
            console.log("Все предметы успешно загружены");
            let subjectsData = data.map((s) => {
                return {
                    ...s,
                    concepts: [],
                    themes: [],
                    graph: {
                        nodes: [],
                        edges: [],
                    },
                    selectedConceptId: null,
                };
            });
            store.dispatch(setSubjects(subjectsData));
            return subjectsData;
        })
        .catch((error) => {
            console.error("Ошибка при загрузке всех предметов", error);
            return false;
        });
    return result;
};

export const postNode = async (node) => {
    const requestBody = {
        node: node,
    };
    console.log("Пытаемся создать новую вершину", requestBody);
    let result = await apiClient(`nodes/`, requestBody)
        .then((res) => res.json())
        .then((data) => {
            console.log("Вершина успешно создана", data);
            return data.node;
        })
        .catch((error) => {
            console.error("Ошибка при создании вершины", error);
            return error;
        });
    return result;
};

export const updateNode = async (selectedNodeId, nodeBody) => {
    console.log("Пытаемся отредактировать вершину", selectedNodeId, nodeBody);
    let result = await apiClient(`nodes/${selectedNodeId}/`, nodeBody, "PUT")
        .then((res) => {
            console.log("Вершина успешно отредактирована");
            return true;
        })
        .catch((error) => {
            console.error("Ошибка при редактировании вершины", error);
            return false;
        });
    return result;
};

export const deleteNode = async (selectedNodeId) => {
    console.log("Пытаемся удалить вершину", selectedNodeId);
    let result = await apiClient(`nodes/${selectedNodeId}/`, null, "DELETE")
        .then((res) => {
            console.log("Вершина успешно удалена");
            return true;
        })
        .catch((error) => {
            console.error("Ошибка при удалении вершины", error);
            return false;
        });
    return result;
};

export const deleteEdge = async (selectedEdgeId) => {
    console.log("Пытаемся удалить связь", selectedEdgeId);
    let result = await apiClient(`relations/${selectedEdgeId}/`, null, "DELETE")
        .then((res) => {
            console.log("Связь успешно удалена");
            return true;
        })
        .catch((error) => {
            console.error("Ошибка при удалении связи", error);
            return false;
        });
    return result;
};

// добавить связь
export const addLink = async ({ from, to }) => {
    const requestBody = {
        relation: {
            parent: from,
            child: to,
        },
    };
    console.log("Пытаемся добавить связь", requestBody);
    let result = await apiClient(`relations/`, requestBody)
        .then((res) => res.json())
        .then((json) => {
            if (json.success) {
                console.log("Успешно создана связь", json);
                let newLink = {
                    id: json.relation.id,
                    from: json.relation.parent,
                    to: json.relation.child,
                };
                return newLink;
            } else {
                console.error("Ошибка при добавлении связи");
            }
        })
        .catch((error) => {
            console.error("Ошибка при добавлении связи", error);
            return false;
        });
    return result;
};

export const loadQuestionsForNode = async (nodeId) => {
    console.log("Старт загрузки задач для вопроса", nodeId);
    let result = await apiClient(`questions/?node=${nodeId}&answer=True`)
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешная загрузка задач для вопроса");
            return data;
        })
        .catch((error) => {
            console.error("Ошибка при загрузке задач для вопроса", error);
            return false;
        });
    return result;
};

export const getThemes = async () => {
    console.log("Начинаем загружать список тем рубрикатора");
    let result = await apiClient("tags/themes/")
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружен список тем рубрикатора");
            store.dispatch(setThemes(data));
        })
        .catch((error) => {
            console.error("Ошибка при загрузке тем", error);
            return false;
        });
    return result;
};

export const getExamTree = async () => {
    console.log("Начинаем загружать дерево экзаменов для методиста");
    await apiClient("tags/tree/")
        .then((res) => res.json())
        .then((data) => {
            console.log("Успешно загружено дерево экзаменов");
            store.dispatch(setExamTree(data));
        })
        .catch((error) => {
            console.log(error, "fetch exam tree error");
        });
};
