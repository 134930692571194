import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
    findSelectedSubject,
    findSelectedExamById,
} from "../features/subjects/subjectsSelectors";
import AProgressBar from "../components/particles/AProgressBar";
import {
    getExamSubjectNumStats,
    getExamThemeStats,
} from "../features/stats/statsApi";
import { getExamSubjectNumUtr, getThemeUtr } from "../features/tasks/tasksApi";
import { useHistory } from "react-router-dom";

/**
 * PROGRESS
 */

const ProgressContainer = styled.div`
    padding-bottom: 15px;
`;

const ProgressComponent = ({
    allQuestionsCount,
    solvedQuestions,
    percent,
    variant,
}) => {
    return (
        <ProgressContainer>
            <AProgressBar value={(solvedQuestions / allQuestionsCount) * 100} />
            <Row
                className={
                    variant === "light" ? "text-white" : "text-secondary"
                }
            >
                <Col>
                    <small>
                        Решено {solvedQuestions}\{allQuestionsCount} задач
                    </small>
                </Col>
                <Col className="text-end">
                    <small>{percent || 0}% готовности</small>
                </Col>
            </Row>
        </ProgressContainer>
    );
};

/**
 * THEMES
 */

const ThemeContainer = styled.div`
    padding: 20px 20px 0 20px;
`;

const ThemeTitle = styled.h4`
    color: ${(props) => props.theme.const.Colors.darkGrayAccent};
`;

const ThemesList = ({ themes, num, examId, subjectId }) => {
    return themes.map((theme, idx) => (
        <ThemeComponent
            theme={theme}
            idx={idx}
            num={num}
            examId={examId}
            subjectId={subjectId}
        />
    ));
};

const ThemeComponent = ({ theme, idx, num }) => {
    const [themeStats, setThemeStats] = useState();

    const history = useHistory();

    useEffect(() => {
        getExamThemeStats(theme.id).then((stats) => {
            setThemeStats(stats);
        });
    }, [theme]);

    const onThemeClick = () => {
        getThemeUtr(theme.id).then((utr) => {
            history.push(`/task/${utr.id}`);
        });
    };

    return (
        <ThemeContainer>
            <ThemeTitle onClick={onThemeClick} style={{ cursor: "pointer" }}>
                {num.num}.{idx + 1}. {theme.title}
            </ThemeTitle>
            <ProgressComponent
                variant={"dark"}
                percent={themeStats ? themeStats.percent : 0}
                allQuestionsCount={theme.questions_count}
                solvedQuestions={themeStats ? themeStats.total_solved : 0}
            />
        </ThemeContainer>
    );
};

/**
 * NUM
 */

const NumsList = ({ nums, examId, subjectId }) => {
    return nums.map((num) => (
        <NumComponent num={num} examId={examId} subjectId={subjectId} />
    ));
};

const NumContainer = styled.div`
    background-color: ${(props) => props.theme.const.Colors.lightGrayAccent};
    border-radius: 5px;
    margin-bottom: 15px;
`;

const NumHeaderContainer = styled.div`
    background-color: ${(props) => {
        if (props.solved) {
            return props.theme.const.Colors.blueAccent;
        } else {
            return props.theme.const.Colors.darkGrayAccent;
        }
    }};

    border-radius: 5px;
    padding: 15px 15px 0 15px;
`;

const ThemesContainer = styled.div`
    padding-bottom: 0px;
`;

const NumComponent = ({ num, examId, subjectId }) => {
    const [isModalOpen, setIsModalOpen] = useState();
    const [numStats, setNumStats] = useState();
    const solved = numStats ? numStats.total_solved !== 0 : false;

    const history = useHistory();

    useEffect(() => {
        getExamSubjectNumStats(examId, subjectId, num.num).then((stats) => {
            setNumStats(stats);
        });
    }, [num, examId, subjectId]);

    const onNumClick = () => {
        getExamSubjectNumUtr(examId, subjectId, num.num).then((utr) => {
            history.push(`/task/${utr.id}`);
        });
    };

    return (
        <NumContainer>
            <NumHeaderContainer solved={solved}>
                {num ? (
                    <p
                        className="text-white mb-0"
                        onClick={onNumClick}
                        style={{ cursor: "pointer" }}
                    >
                        <small>Задание {num.num}.</small>
                    </p>
                ) : (
                    false
                )}
                <h4
                    className="text-white"
                    onClick={onNumClick}
                    style={{ cursor: "pointer" }}
                >
                    {num.title
                        ? num.title
                        : num.themes[0].title
                        ? num.themes[0].title
                        : `Задание №${num.num}`}
                </h4>
                {/* <pre>{JSON.stringify(num, null, 2)}</pre>
                <pre>{JSON.stringify(numStats, null, 2)}</pre> */}
                <ProgressComponent
                    variant={"light"}
                    percent={numStats ? numStats.percent : 0}
                    allQuestionsCount={num.all_questions_count}
                    solvedQuestions={numStats ? numStats.total_solved : 0}
                />
                {num.themes.length > 1 && (
                    <Button
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        variant={"light"}
                        className={"btn-sm mb-3"}
                    >
                        {isModalOpen ? "Скрыть" : "Подробнее"}
                    </Button>
                )}
            </NumHeaderContainer>
            {isModalOpen ? (
                <ThemesContainer>
                    <ThemesList
                        themes={num.themes}
                        num={num}
                        examId={examId}
                        subjectId={subjectId}
                    />
                </ThemesContainer>
            ) : (
                false
            )}
        </NumContainer>
    );
};

const ExamTrainer = () => {
    const selectedSubject = useSelector((state) => findSelectedSubject(state));
    const selectedSubjectId = selectedSubject.id;
    const selectedExamId = selectedSubject.selectedExamId;
    const selectedExam = useSelector((state) =>
        findSelectedExamById(state, selectedSubjectId, selectedExamId)
    );

    return (
        <div>
            <NumsList
                nums={selectedExam.nums}
                examId={selectedExamId}
                subjectId={selectedSubjectId}
            />
        </div>
    );
};

export default ExamTrainer;
