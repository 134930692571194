import React, { useEffect, useState } from "react";
import { Card, Form, Button, Image } from "react-bootstrap";
import {
    getQuestion,
    postQuestionAnswer,
} from "../../features/questions/questionsApi";
import MarkdownRenderer from "../particles/MarkdownRenderer";
import { Spinner } from "react-bootstrap";
import Options from "./Options";
import { BASE_URL } from "../../backend/http";

const Question = ({ questionId, utrId, onNextQuestion, onFinish }) => {
    const [question, setQuestion] = useState();
    const [questionStatus, setQuestionStatus] = useState(0); // 0 - start, 1 - fetching result, 2 - already get result from server

    const [optionsData, setOptionsData] = useState();
    const [isQuestionLoading, setIsQuestionLoading] = useState(true);

    const [answer, setAnswer] = useState();
    const [answerError, setAnswerError] = useState();

    const [answerResult, setAnswerResult] = useState();

    useEffect(() => {
        getQuestion(questionId).then((data) => {
            console.log(data);
            setQuestion(data);
            if (data.options_data) {
                setOptionsData(
                    data.options_data.sort(() => 0.5 - Math.random())
                );
            }
            setIsQuestionLoading(false);
        });
    }, [questionId]);

    const onNextQuestionClick = () => {
        setQuestion(null);
        setQuestionStatus(0);
        setOptionsData(null);
        setIsQuestionLoading(true);
        setAnswer("");
        setAnswerError(null);
        setAnswerResult(null);
        onNextQuestion();
    };

    const sendAnswer = () => {
        let answerReady;
        if (question.question_type === "ChoiceQuestion") {
            answerReady = [];
            for (let option of optionsData) {
                if (option.selected) {
                    answerReady.push(option.id);
                }
            }
        } else if (question.question_type === "FloatAnswerQuestion") {
            answerReady = answer.replace(",", ".");
        } else {
            answerReady = answer;
        }
        setQuestionStatus(1);
        postQuestionAnswer(utrId, questionId, answerReady).then((data) => {
            setQuestionStatus(2);
            setAnswerResult(data);
        });
    };

    const onAnswerChange = (event) => {
        const newAnswer = event.target.value;
        setAnswer(newAnswer);
        if (!newAnswer) {
            setAnswerError("Введите ответ");
        } else if (question.question_type === "FloatAnswerQuestion") {
            let _answer = newAnswer.replace(",", ".");
            if (isNaN(+_answer)) {
                setAnswerError("Введите число");
            } else {
                setAnswerError(null);
            }
        } else {
            setAnswerError(null);
        }
    };

    const onOptionClick = (optionId) => {
        if (question.is_multiple) {
            let newOptionsArray = optionsData.map((op) => {
                if (op.id === optionId) {
                    if (op.selected) {
                        op.selected = !op.selected;
                    } else {
                        op.selected = true;
                    }
                }
                return op;
            });
            setOptionsData(newOptionsArray);
        } else {
            let newOptionsArray = optionsData.map((op) => {
                if (op.id === optionId) {
                    op.selected = true;
                } else {
                    op.selected = false;
                }
                return op;
            });
            setOptionsData(newOptionsArray);
        }
    };

    return (
        <Card className="mb-3">
            <Card.Body>
                <Card.Text className="text-muted">
                    Question {questionId}
                </Card.Text>
                {!isQuestionLoading && question ? (
                    <>
                        <MarkdownRenderer
                            markdownText={question.question_text}
                        />
                        {question.image ? (
                            <Image
                                rounded={true}
                                src={question.image.replace(
                                    "/media",
                                    BASE_URL + "/media"
                                )}
                            />
                        ) : null}
                        {question.question_type === "ChoiceQuestion" &&
                        optionsData ? (
                            <>
                                <p className="text-muted">
                                    {question.is_multiple
                                        ? "Выбери все верные ответы"
                                        : "Выбери верный ответ"}
                                </p>
                                <Options
                                    optionsData={optionsData}
                                    questionStatus={questionStatus}
                                    onOptionClick={onOptionClick}
                                    isMultiple={question.is_multiple}
                                />
                            </>
                        ) : (
                            <Form.Group className="mb-3">
                                <Form.Label>Ответ</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="answer"
                                    onChange={onAnswerChange}
                                    value={answer}
                                    disabled={questionStatus > 0}
                                    isValid={
                                        questionStatus === 2 &&
                                        answerResult &&
                                        answerResult.result.max_score ===
                                            answerResult.result.score
                                    }
                                    isInvalid={
                                        answerError ||
                                        (questionStatus === 2 &&
                                            answerResult &&
                                            answerResult.result.max_score !==
                                                answerResult.result.score)
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {answerError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                        {questionStatus === 0 ? (
                            <Button disabled={answerError} onClick={sendAnswer}>
                                Проверить ответ
                            </Button>
                        ) : (
                            false
                        )}
                        {questionStatus === 1 ? (
                            <Button disabled={true}>...Отправляем</Button>
                        ) : (
                            false
                        )}
                        {questionStatus === 2 && answerResult ? (
                            <>
                                {answerResult.next_question_pk !== -1 ? (
                                    <Button onClick={onNextQuestionClick}>
                                        Дальше
                                    </Button>
                                ) : (
                                    <Button onClick={onFinish}>Конец</Button>
                                )}
                                {question.explanation_text ? (
                                    <div className="mt-3">
                                        {question.right_answer ? (
                                            <p>
                                                <b>
                                                    Верный ответ:{" "}
                                                    {question.right_answer}
                                                </b>
                                            </p>
                                        ) : (
                                            false
                                        )}
                                        <MarkdownRenderer
                                            markdownText={
                                                question.explanation_text
                                            }
                                        />
                                    </div>
                                ) : (
                                    false
                                )}
                            </>
                        ) : (
                            false
                        )}
                    </>
                ) : (
                    <Spinner animation="grow" />
                )}
            </Card.Body>
        </Card>
    );
};

export default Question;
