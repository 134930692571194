import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { BASE_URL } from "../../backend/http";
import MarkdownRenderer from "../particles/MarkdownRenderer";

const OptionCardContainer = styled.div`
    background-color: ${(props) => {
        if (props.verified) {
            if (props.isTrue) {
                return props.theme.const.Colors.greenMessage50;
            } else {
                return props.theme.const.Colors.redMessage50;
            }
        } else {
            if (props.selected) {
                return props.theme.const.Colors.blueAccent50;
            } else {
                return props.theme.colors.bgColor;
            }
        }
    }};
    margin-bottom: 8px;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
`;

const OptionDetector = styled.div`
    background-color: ${(props) => {
        if (props.selected) {
            return props.theme.const.Colors.white;
        } else {
            return props.theme.const.Colors.blueAccent;
        }
    }};
    opacity: ${(props) => {
        if (props.verified && !props.selected) {
            return 0;
        } else {
            return 1;
        }
    }};
    width: 32px;
    height: 32px;
    padding: 5px;
    margin-right: 10px;
    border-radius: ${(props) => (props.variant === "radio" ? 16 : 5)}px;
`;

const OptionDetectorInner = styled.div`
    background-color: ${(props) => {
        if (props.selected) {
            if (props.verified) {
                if (props.isTrue) {
                    return props.theme.const.Colors.greenMessage;
                } else {
                    return props.theme.const.Colors.redMessage;
                }
            } else {
                return props.theme.const.Colors.blueAccent;
            }
        } else {
            return props.theme.const.Colors.white;
        }
    }};
    width: 22px;
    height: 22px;
    border-radius: ${(props) => (props.variant === "radio" ? 12 : 3)}px;
`;

const OptionInner = styled.div`
    flex: 1;
    overflow: hidden;
    justify-content: center;
`;

const OptionCard = ({ option, variant = "radio", questionStatus, onClick }) => {
    return (
        <OptionCardContainer
            className={"d-flex align-items-center"}
            selected={option.selected}
            verified={questionStatus > 1}
            isTrue={option.is_true}
            onClick={() => {
                if (questionStatus === 0) {
                    onClick(option.id);
                }
            }}
        >
            <OptionDetector
                className="d-inline-block"
                variant={variant}
                selected={option.selected}
                verified={questionStatus > 1}
            >
                <OptionDetectorInner
                    selected={option.selected}
                    variant={variant}
                    isTrue={option.is_true}
                    verified={questionStatus > 1}
                />
            </OptionDetector>
            <OptionInner className="d-inline-block">
                {option.option_text ? (
                    <MarkdownRenderer markdownText={option.option_text} />
                ) : null}
                {option.option_image ? (
                    <Image
                        rounded={true}
                        src={option.option_image.replace(
                            "/media",
                            BASE_URL + "/media"
                        )}
                    />
                ) : null}
            </OptionInner>
        </OptionCardContainer>
    );
};

const Options = ({
    isMultiple,
    optionsData,
    questionStatus,
    onOptionClick,
}) => {
    return (
        <div>
            {optionsData.map((option) => (
                <OptionCard
                    key={option.id}
                    variant={isMultiple ? "checkbox" : "radio"}
                    option={option}
                    questionStatus={questionStatus}
                    onClick={onOptionClick}
                />
            ))}
        </div>
    );
};

export default Options;
