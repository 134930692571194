export const nodeColors = ["#E37961", "#F3C847", "#FDF368", "#9ECE62"];
export const nodeTitles = ["Знаю", "Понимаю", "Кейс", "Умение"];
export const questionTypes = [
    {
        short: "float",
        long: "FloatAnswerQuestion",
        ru: "Ответ числом",
    },
    {
        short: "string",
        long: "StringAnswerQuestion",
        ru: "Ответ строкой",
    },
    {
        short: "choice",
        long: "ChoiceAnswerQuestion",
        ru: "Выбор ответа",
    },
    // {
    //     short: 'exam',
    //     long: 'ExamQuestion',
    //     ru: 'Задание экзамена'
    // }
];
