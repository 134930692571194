/**
 * Colors
 */
export const Colors = {
    black: "rgba(29, 29, 27, 1)",
    darkGrayAccent: "rgba(96, 96, 96, 1)",
    grayAccent: "rgba(219, 219, 218, 1)",
    lightGrayAccent: "rgba(233, 233, 233, 1)",
    brightGrayAccent: "rgba(246, 246, 243, 1)",
    white: "rgba(255, 255, 255, 1)",

    pinkAccent: "rgba(237, 99, 122, 1)",
    greenAccent: "rgba(115, 208, 164, 1)",
    blueAccent: "rgba(89, 126, 231, 1)",

    greenMessage: "rgba(98, 196, 83, 1)", // оценка 4-5
    yellowMessage: "rgba(245, 189, 79, 1)", // оценка 3-4
    redMessage: "rgba(236, 106, 95, 1)", // оценка 2

    blueAccent50: "rgba(89, 126, 231, 0.5)",
    greenMessage50: "rgba(98, 196, 83, 0.5)",
    redMessage50: "rgba(236, 106, 95, 0.5)",
};

/**
 * Fonts
 */
export const fontSizesInt = {
    tiny: 12,
    cards: 14,
    small: 16,
    input: 18,
    regular: 20,
    large: 40,
    h1: 28,
    h2: 23,
    h3: 20,
};

export const Fonts = {
    family: {
        main: "OpenSans",
        bold: "OpenSans-Bold",
    },
    sizes: {
        tiny: fontSizesInt.tiny + "px",
        cards: fontSizesInt.cards + "px",
        small: fontSizesInt.small + "px",
        input: fontSizesInt.input + "px",
        regular: fontSizesInt.regular + "px",
        large: fontSizesInt.large + "px",
        h1: fontSizesInt.h1 + "px",
        h2: fontSizesInt.h2 + "px",
        h3: fontSizesInt.h3 + "px",
    },
    sizesInt: fontSizesInt,
};
