import {
    apiClient,
    httpClient,
    handleError,
    httpRequestWithFile,
} from "../../backend/http";
import { store } from "../../store";
import { setUser, setToken, logout, updateUser } from "./authReducer";

// Загружаем данные пользователя
export const getUser = async () => {
    console.log("Начало загрузки данных пользователя");
    let result = await apiClient(`user/`)
        .then((response) => response.json())
        .then((data) => {
            console.log("Успешно загружены данные пользователя", data);
            store.dispatch(setUser(data));
            return data;
        })
        .catch((e) => {
            console.error(
                { message: `Request to "user/" failed, ${e}` },
                "API error"
            );
        });

    return result;
};

export const checkAuth = async () => {
    let result = await apiClient("check/")
        .then((response) => response.json())
        .then((res) => true)
        .catch((error) => {
            store.dispatch(logout());
            console.log("Check token failed");
            return false;
        });
    return result;
};

export const getUserStats = async () => {
    console.log("Начало загрузки результатов пользователя");
    let result = await apiClient("stat/solved/")
        .then((response) => response.json())
        .then((data) => {
            console.log("Успешно загружены результаты пользователя", data);
            return data.solved_questions;
        })
        .catch((error) => {
            handleError(error, "get stats error");
        });
    return result;
};

export const getToken = async (username, password) => {
    const url = "email-auth/";
    let result = await httpClient(url, {
        email: username,
        password: password,
    })
        .then((response) => {
            if (!response.ok) {
                return response.text().then((json) => {
                    throw new Error(json);
                });
            } else {
                return response.json();
            }
        })
        .then((data) => {
            console.log("Token auth success", data);
            store.dispatch(setToken(data.token));
            return data.token;
        })
        .catch((e) => {
            console.error(
                { message: `Request to "email-auth/" failed, ${e}` },
                "API error"
            );
            return {
                error: {
                    message: "Невозможно войти с указанными данными",
                },
            };
        });

    return result;
};

export const regUser = async (values) => {
    console.log("Начало регистрации пользователя по email", values);
    let result = await httpClient(`email-register/`, values)
        .then((response) => {
            if (!response.ok) {
                return response.json().then((json) => {
                    throw new Error(json.message);
                });
                // let err = new Error(`Ошибка (${response.status}) при регистрации пользователя через email: ${response.message}`)
                // err.response = response
                // err.status = response.status
                // throw err
            }
            return response.json();
        })
        .then((data) => {
            console.log("Успешно зарегистрирован пользователь", data.name);
            store.dispatch(setToken(data.token));
            return data;
        })
        .catch((e) => {
            console.error(
                { message: `Ошибка при регистрации пользователя, ${e}` },
                "API error"
            );
            return {
                error: {
                    message: `${e}`,
                },
            };
        });

    return result;
};

export const postUserUpdates = async (values) => {
    console.log("Начало обновления информации о пользователе");
    let result = await apiClient("user/", values)
        .then((response) => response.json())
        .then((data) => {
            console.log("Успешно обновлена информация пользователя", data);
            store.dispatch(updateUser(values));
            return data;
        })
        .catch((error) => {
            handleError(error, "post user info error");
        });
    return result;
};

export const postUserAvatar = async (avatar, user) => {
    const data = new FormData();
    data.append("upload", avatar);

    let result = await httpRequestWithFile(`user/avatar/`, data)
        .then((imgRes) => {
            console.log("Img res", imgRes);
        })
        .catch((error) => {
            handleError(error, "update user avatar error");
            return false;
        });
    return result;
};
