import React, { useCallback, useEffect, useState } from "react";
import {
    Row,
    Spinner,
    Col,
    Card,
    Form,
    Button,
    Modal,
    Alert,
    Breadcrumb,
} from "react-bootstrap";
import { apiClient } from "../../backend/http";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import { QuestionForm } from "../../components/methodics/QuestionForm";
import { QuestionsList } from "../../components/methodics/QuestionsList";
import { useHistory } from "react-router-dom";
import { getExamTree } from "../../features/methodics/methodicsApi";

// TODO: добавить тестовое задание и протестировать отображение вопросов
export function TrainerCreation() {
    let history = useHistory();

    const globalData = useSelector((store) => store.methodics);
    const selectedSubjectTagId = globalData.selectedSubjectId;
    const selectedSubject = globalData.subjects.find(
        (obj) => obj.id === selectedSubjectTagId
    );
    const subjectExams = selectedSubject.exams;
    const subjectThemes = selectedSubject.themes;

    const [selectedThemes, setSelectedThemes] = useState([]);
    const [questions, setQuestions] = useState();
    const [editingQuestion, setEditingQuestion] = useState();

    const [showTreeModal, setShowTreeModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const loadQuestions = useCallback(() => {
        // TODO: add multimple themes questions
        let requests = [];

        for (let themeId of selectedThemes) {
            requests.push(
                apiClient(`questions/?themetag=${themeId}&answer=True`)
                    .then((res) => res.json())
                    .then((json) => {
                        return json;
                    })
            );
        }

        let questionsArray = [];

        Promise.all(requests).then((dataArray) => {
            for (let data of dataArray) {
                questionsArray = [...questionsArray, ...data];
            }

            let unique = questionsArray.filter(
                (ele, ind) =>
                    ind ===
                    questionsArray.findIndex((elem) => elem.id === ele.id)
            );
            setQuestions(unique);
        });
    }, [selectedThemes]);

    // Загружаем список концептов для выбора
    useEffect(() => {
        if (subjectExams && subjectExams.length === 0) {
            getExamTree();
        }
    }, []);

    useEffect(() => {
        loadQuestions();
    }, [selectedThemes, loadQuestions]);

    const ThemeComponent = ({ theme }) => {
        const isThemeSelected = selectedThemes.includes(theme.id);

        return (
            <li className={isThemeSelected ? "text-danger" : ""}>
                <Form.Check
                    onChange={() => {
                        let newSelectedThemes;
                        if (isThemeSelected) {
                            let oldSelectedThemes = [...selectedThemes];
                            newSelectedThemes = oldSelectedThemes.filter(
                                function (obj) {
                                    return obj !== theme.id;
                                }
                            );
                        } else {
                            newSelectedThemes = [...selectedThemes];
                            newSelectedThemes.push(theme.id);
                        }
                        setSelectedThemes(newSelectedThemes);
                    }}
                    checked={isThemeSelected}
                    type="checkbox"
                    label={`${theme["title"]} (Заданий: ${theme["questions_count"]})`}
                />
            </li>
        );
    };

    const ThemesList = ({ themes }) => {
        const list = themes.map((theme) => {
            return <ThemeComponent key={uuid()} theme={theme} />;
        });
        return <ul style={{ listStyleType: "none" }}>{list}</ul>;
    };

    const NumComponent = ({ num }) => {
        let numTitle = "";

        if (num["title"]) {
            numTitle = num["title"];
        } else {
            numTitle = num.themes[0].title;
        }

        return (
            <li>
                <span
                    onClick={() => {
                        let themesIdsArray = [];
                        for (let theme of num.themes) {
                            themesIdsArray.push(theme.id);
                        }
                        let newSelectedThemes = [
                            ...selectedThemes,
                            ...themesIdsArray,
                        ];
                        setSelectedThemes(newSelectedThemes);
                    }}
                >
                    Задание {num.num}. {numTitle} (Заданий:{" "}
                    {num.all_questions_count})
                </span>
                <ThemesList themes={num.themes} />
            </li>
        );
    };

    const NumsList = ({ nums, old }) => {
        const list = nums.map((num) => {
            return <NumComponent key={uuid()} num={num} />;
        });
        return <ul>{list}</ul>;
    };

    const ExamComponent = ({ exam }) => {
        return (
            <li>
                {exam["title"]}
                <NumsList nums={exam.nums} />
            </li>
        );
    };

    const ExamsList = ({ exams }) => {
        const list = exams.map((exam) => {
            return <ExamComponent key={uuid()} exam={exam} />;
        });
        return <ul>{list}</ul>;
    };

    const TagsTree = () => {
        if (subjectExams.length !== 0) {
            return (
                <Card>
                    <Card.Body>
                        <ExamsList exams={subjectExams} />
                    </Card.Body>
                </Card>
            );
        } else {
            return (
                <div className={"mt-4"}>
                    <Spinner animation="grow" />
                </div>
            );
        }
    };

    const SelectedThemesComponent = () => {
        const list = selectedThemes.map((themeId) => {
            const themeObject = subjectThemes.find((object) => {
                return object.id === themeId;
            });

            if (themeObject) {
                return (
                    <li key={uuid()}>
                        {themeObject.theme.title} (№{themeObject.num})
                    </li>
                );
            } else {
                return (
                    <li key={uuid()}>
                        Ждем когда Катя отключит пагинацию на /tags/themes
                    </li>
                );
            }
        });

        return (
            <Card>
                <Card.Body>
                    <Card.Text>Выбранные темы</Card.Text>
                    {list.length !== 0 ? (
                        <ul>{list}</ul>
                    ) : (
                        <Alert variant={"danger"}>Выбери темы</Alert>
                    )}
                    <Button onClick={() => setShowTreeModal(true)}>
                        Список тем
                    </Button>
                </Card.Body>
            </Card>
        );
    };

    return (
        <div>
            <Row className={"mt-3"}>
                <Breadcrumb>
                    <Breadcrumb.Item
                        href={"#"}
                        onClick={() => history.push("/")}
                    >
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        href={"#"}
                        onClick={() => history.push("/methodics")}
                    >
                        Интерфейс методиста
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Тренажер по предмету "{selectedSubject.subject.title}"
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Col xs={4}>
                    <SelectedThemesComponent />
                </Col>
                {selectedThemes.length !== 0 && (
                    <Col xs={8}>
                        <Card>
                            <Card.Body>
                                <Card.Text>Список вопросов по теме</Card.Text>
                                <QuestionsList
                                    questions={questions}
                                    onClickEditButton={(editedQuestion) => {
                                        setShowEditModal(true);
                                        setEditingQuestion(editedQuestion);
                                    }}
                                />
                                <Card.Text>
                                    <Button
                                        variant="success"
                                        onClick={() => setShowCreateModal(true)}
                                        className={"mt-2"}
                                    >
                                        + Добавить новое
                                    </Button>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                )}
            </Row>

            <Modal
                show={showTreeModal}
                onHide={() => setShowTreeModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Список тем</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TagsTree />
                </Modal.Body>
            </Modal>

            <Modal
                show={showCreateModal}
                onHide={() => setShowCreateModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Создание задания</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuestionForm
                        initialThemes={selectedThemes}
                        onSubmit={() => {
                            setShowCreateModal(false);
                            loadQuestions();
                        }}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showEditModal}
                onHide={() => {
                    setShowEditModal(false);
                    setEditingQuestion(null);
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование задания</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuestionForm
                        question={editingQuestion}
                        onSubmit={() => {
                            setShowEditModal(false);
                            setEditingQuestion(null);
                            loadQuestions();
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}
