import React from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { getToken } from "../features/auth/authApi";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export function Login() {
    const history = useHistory();
    const [showEmail, setShowEmail] = useState(false);

    const onVkAuth = () => {
        const VK_APP_ID = 7687895;

        const redirect_uri = `https://stagging.adaptaki.ru/vk-oauth/redirect/`;

        const vkLoginUrl = `https://oauth.vk.com/authorize?client_id=${VK_APP_ID}&display=popup&response_type=code&redirect_uri=${redirect_uri}`;

        console.log(vkLoginUrl);
    };

    return (
        <div className="mt-5">
            <Row>
                <Col xs={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
                    <Card>
                        <Card.Body>
                            <h3>Вход на платформу</h3>
                            {showEmail ? (
                                <Formik
                                    initialValues={{
                                        email: "",
                                        password: "",
                                    }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.email) {
                                            errors.email = "Введите Email";
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                values.email
                                            )
                                        ) {
                                            errors.email = "Некорректный email";
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(
                                        values,
                                        { setSubmitting, setFieldError }
                                    ) => {
                                        getToken(
                                            values.email,
                                            values.password
                                        ).then((data) => {
                                            if (data.error) {
                                                setFieldError(
                                                    "email",
                                                    data.error.message
                                                );
                                            }
                                            setSubmitting(false);
                                        });
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (
                                        <Form
                                            onSubmit={handleSubmit}
                                            validated={false}
                                        >
                                            <Form.Group className="mb-2">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    isInvalid={
                                                        errors.email &&
                                                        touched.email
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Пароль</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    isInvalid={
                                                        errors.password &&
                                                        touched.password
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Войти
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <div className="d-grid gap-2 mt-3">
                                    <Button
                                        variant="outline-primary"
                                        className="btn-lg"
                                        onClick={() => setShowEmail(true)}
                                    >
                                        Войти через почту
                                    </Button>
                                </div>
                            )}
                            {/* <div className="d-grid gap-2 mt-3">
                                <Button
                                    variant="outline-primary"
                                    className="btn-lg"
                                    onClick={() => onVkAuth()}
                                >
                                    Войти через VK
                                </Button>
                            </div> */}
                            <div className="d-grid gap-2 mt-3">
                                <Button
                                    variant="outline-secondary"
                                    className="btn-lg"
                                    onClick={() => history.push("/reg")}
                                >
                                    Зарегистрироваться
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
