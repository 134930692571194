export const nodeTitles = ["Знаю", "Понимаю", "Кейс", "Умение"];

// Преобразует вид графа с сервера в граф для vis.js
const nodeColors = ["#E37961", "#F3C847", "#FDF368", "#9ECE62"];

export const calculateGraphVisData = (graph) => {
    let calculatedNodes = graph.nodes.map((node) => {
        let newNode = {
            id: node.id,
            title: node.data.title,
            label: node.data.title,
            data: node.data,
            features: node.features,
            questionsCount: node.questions_count,
            testability: node.testability,
            borderWidth: 2,
        };

        let nodeBorderColor = "black";
        if (node.data.testability) {
            // ['Знаю', 'Понимаю', 'Кейс', 'Умение']
            if (node.data.type === 4) {
                if (node.questions_count > 3) {
                    nodeBorderColor = "green";
                } else {
                    nodeBorderColor = "red";
                }
            } else if (node.data.type < 4) {
                if (node.questions_count > 0) {
                    nodeBorderColor = "green";
                } else {
                    nodeBorderColor = "red";
                }
            }
        }

        let nodeFillColor = node.data.is_gray
            ? "#DDDDDD"
            : nodeColors[node.data.type - 1];

        newNode.color = {
            background: nodeFillColor,
            border: nodeBorderColor,
        };

        newNode.shape = "dot";
        if (node.features) {
            if (node.features.in_count === 0) {
                newNode.shape = "star";
            } else if (node.features.out_count === 0) {
                newNode.shape = "diamond";
            }
        }

        if (node.data.concept) {
            newNode.group = node.data.concept;
        }

        return newNode;
    });

    return {
        nodes: calculatedNodes,
        edges: graph.edges,
    };
};

export const graphOptions = (variant) => {
    if (variant === "hierarchical") {
        return {
            edges: {
                smooth: {
                    type: "cubicBezier",
                    forceDirection: "horizontal",
                    roundness: 0.4,
                },
                arrows: "middle",
                color: {
                    inherit: false,
                    opacity: 0.5,
                    highlight: "red",
                },
            },
            nodes: {
                shape: "dot",
                scaling: {
                    min: 5,
                    max: 40,
                },
                widthConstraint: 160,
                color: {
                    highlight: "red",
                },
                margin: 40,
            },
            height: "100%",
            interaction: { hover: true },
            autoResize: true,
            layout: {
                improvedLayout: true,
                hierarchical: {
                    direction: "UD", // Works fine
                    sortMethod: "directed", // Works fine, hubsize destroy directions
                    shakeTowards: "leaves",
                    parentCentralization: true,

                    levelSeparation: 200, // The distance between the different levels.
                    treeSpacing: 30, // Distance between different trees (independent networks)
                    nodeSpacing: 120, // If you enable physics, the node distance there will be the effective node distance.
                },
            },
            physics: {
                enabled: true,
                hierarchicalRepulsion: {
                    centralGravity: 0.0,
                    springLength: 100,
                    springConstant: 0.01,
                    nodeDistance: 220,
                    damping: 0.09,
                    avoidOverlap: 0,
                },
            },
        };
    } else {
        return {
            edges: {
                arrows: "middle",
                color: {
                    inherit: false,
                    opacity: 0.5,
                    highlight: "red",
                },
            },
            nodes: {
                shape: "dot",
                scaling: {
                    min: 5,
                    max: 40,
                },
                widthConstraint: 160,
                color: {
                    highlight: "red",
                },
            },
            autoResize: true,
            height: "100%",
            layout: {
                hierarchical: false,
                improvedLayout: false,
            },
            physics: {
                enabled: true,
            },
        };
    }
};

export const calculateGraphForConcept = (
    subjectGraph,
    selectedConceptTagId,
    showGrayNodes = false
) => {
    let graph = subjectGraph;

    let conceptNodes = graph.nodes.filter(
        (obj) => obj.data.concept === selectedConceptTagId
    );

    let conceptNodesIds = [];
    for (let node of conceptNodes) {
        conceptNodesIds.push(node.id);
    }

    if (showGrayNodes) {
        let conceptEdgesWithGray = graph.edges.filter((obj) => {
            if (conceptNodesIds.includes(obj.from)) {
                return true;
            } else if (conceptNodesIds.includes(obj.to)) {
                return true;
            }
            return false;
        });

        let conceptGrayNodesIds = [];

        for (let edge of conceptEdgesWithGray) {
            if (conceptNodesIds.indexOf(edge.to) === -1) {
                // Вершины с указателем to нет в массиве вершин концепта

                // Если такого id еще не было в массиве, добавляем туда
                if (conceptGrayNodesIds.indexOf(edge.to) === -1) {
                    conceptGrayNodesIds.push(edge.to);
                }
            } else if (conceptNodesIds.indexOf(edge.from) === -1) {
                // Вершины с указателем from нет в массиве вершин концепта

                // Если такого id еще не было в массиве, добавляем туда
                if (conceptGrayNodesIds.indexOf(edge.from) === -1) {
                    conceptGrayNodesIds.push(edge.from);
                }
            }
        }

        let conceptGrayNodes = graph.nodes.filter(
            (obj) => conceptGrayNodesIds.indexOf(obj.id) !== -1
        );

        conceptGrayNodes = conceptGrayNodes.map((grayNode) => {
            let newNode = {
                ...grayNode,
                data: { ...grayNode.data, is_gray: true },
            };
            return newNode;
        });

        return {
            nodes: conceptNodes.concat(conceptGrayNodes),
            edges: conceptEdgesWithGray,
        };
    }

    let conceptEdges = graph.edges.filter((obj) => {
        if (conceptNodesIds.includes(obj.from)) {
            if (conceptNodesIds.includes(obj.to)) {
                return true;
            }
        }
        return false;
    });

    return {
        nodes: conceptNodes,
        edges: conceptEdges,
    };
};

export const calculateGraphForNode = (subjectGraph, selectedNodeId) => {
    let graph = subjectGraph;

    let edgesWithSelectedNode = graph.edges.filter((obj) => {
        if (obj.from === selectedNodeId) {
            return true;
        } else if (obj.to === selectedNodeId) {
            return true;
        }
        return false;
    });

    let matchingNodesIds = [];

    for (let edge of edgesWithSelectedNode) {
        if (matchingNodesIds.indexOf(edge.to) === -1) {
            matchingNodesIds.push(edge.to);
        }
        if (matchingNodesIds.indexOf(edge.from) === -1) {
            matchingNodesIds.push(edge.from);
        }
    }

    let matchingNodes = graph.nodes.filter(
        (obj) => matchingNodesIds.indexOf(obj.id) !== -1
    );

    return {
        nodes: matchingNodes,
        edges: edgesWithSelectedNode,
    };
};

export const prepareNodesForSelect = (nodes, selectedNodeId = null) => {
    let nodesForSelectArray = [];

    for (let node of nodes) {
        if (node.id !== selectedNodeId) {
            nodesForSelectArray.push({
                value: node.id,
                label: node.data.title,
            });
        }
    }
    return nodesForSelectArray;
};
