import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import * as Sentry from "@sentry/react";

import authReducer from "./features/auth/authReducer";
import methodicsReducer from "./features/methodics/methodicsReducer";
import subjectsReducer from "./features/subjects/subjectsSlice";
import settingsReducer from "./features/settings/settingsSlice";

const persistConfig = {
    key: "root20",
    version: 1,
    storage,
};

// Config for sentry integration
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

const rootReducer = combineReducers({
    auth: authReducer,
    methodics: methodicsReducer,
    subjects: subjectsReducer,
    settings: settingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk), sentryReduxEnhancer)
);

export const persistor = persistStore(store);
