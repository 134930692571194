// React
import React from "react";

// Redux
import { useSelector } from "react-redux";

// Theme
import { ThemeProvider } from "styled-components";

import { Colors, Fonts } from "./style/constants";

import "bootstrap/dist/css/bootstrap.min.css";

// Base theme
const baseTheme = {
    isDark: false,
    device: {},
    colors: {
        bgColor: Colors.white,
        textColor: Colors.black,
    },
    const: {
        Colors,
        Fonts,
    },
};

const lightTheme = baseTheme;

const darkTheme = {
    ...baseTheme,
    colors: {
        bgColor: Colors.black,
        textColor: Colors.white,
    },
    isDark: true,
};

// Provider for add useState for nested components
export const AppWithTheme = (props) => {
    const darkMode = useSelector((store) => store.settings.darkMode);

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            {props.children}
        </ThemeProvider>
    );
};
