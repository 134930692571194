import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import gfm from "remark-gfm";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you

export const MarkdownRenderer = ({ markdownText }) => {
    return (
        <ReactMarkdown
            className={"renderedWrapper"}
            remarkPlugins={[remarkMath, gfm]}
            rehypePlugins={[rehypeKatex, rehypeRaw]}
            children={markdownText}
        />
    );
};

export default MarkdownRenderer;
