import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        darkMode: false,
        finishOnBoarding: false,
    },
    reducers: {
        setDarkMode: (state, action) => {
            state.darkMode = action.payload;
        },
        setFinishOnBoarding: (state) => {
            state.finishOnBoarding = true;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setDarkMode, setFinishOnBoarding } = settingsSlice.actions;

export default settingsSlice.reducer;
