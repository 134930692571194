import { store } from "../store";

export const BASE_URL = "https://stagging.adaptaki.ru";

export async function httpRequestWithFile(path = "", body = null) {
    let token = store.getState().auth.token;
    const response = await fetch(`${BASE_URL}/api/${path}`, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: { Authorization: `Token ${token}` },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: body, // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
}

export async function httpClient(
    path = "",
    body = null,
    method = null,
    headers = null
) {
    const response = await fetch(`${BASE_URL}/${path}`, {
        method: method ? method : body ? "POST" : "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: { "Content-Type": "application/json", ...headers },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: body ? JSON.stringify(body) : null, // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
}

export async function apiClient(
    path,
    body = null,
    method = null,
    headers = null
) {
    let token = store.getState().auth.token;
    return httpClient(
        `api/${path}`,
        body,
        method,
        token ? { Authorization: `Token ${token}`, ...headers } : headers
    );
}

export const handleError = (error, description) => {
    // Error
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
            description,
            error.response.data,
            error.response.status,
            error.response.headers
        );
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        console.log(description, error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log(description, "Error", error.message);
    }
};

export default apiClient;
