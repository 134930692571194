import React, { useEffect } from "react";
import { Row, Col, ListGroup, Breadcrumb, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
    getExamTree,
    getThemes,
    loadSubjects,
} from "../../features/methodics/methodicsApi";
import { selectSubject } from "../../features/methodics/methodicsReducer";
import { persistor } from "../../store";

export function MethodicsIndex() {
    const subjects = useSelector((store) => store.methodics.subjects);
    const selectedSubjectId = useSelector(
        (store) => store.methodics.selectedSubjectId
    );
    const dispatch = useDispatch();

    let history = useHistory();

    useEffect(() => {
        if (subjects.length === 0) {
            loadSubjects().then(() => {
                getExamTree();
                getThemes();
            });
        }
    }, [subjects]);

    useEffect(() => {
        if (subjects.length !== 0) {
            getExamTree();
            getThemes();
        }
    }, [selectedSubjectId]);

    const onSubjectTagClick = (subjectTag) => {
        dispatch(selectSubject(subjectTag.id));
    };

    const subjectsList =
        subjects.length > 0
            ? subjects.map((subjectTag) => {
                  return (
                      <ListGroup.Item
                          active={
                              selectedSubjectId
                                  ? subjectTag.id === selectedSubjectId
                                  : false
                          }
                          action
                          onClick={() => onSubjectTagClick(subjectTag)}
                          key={subjectTag.id.toString()}
                      >
                          {subjectTag.subject.title}
                      </ListGroup.Item>
                  );
              })
            : false;

    return (
        <div>
            <Row className={"mt-3"}>
                <Breadcrumb>
                    <Breadcrumb.Item
                        href={"#"}
                        onClick={() => history.push("/")}
                    >
                        Главная
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Интерфейс методиста
                    </Breadcrumb.Item>
                </Breadcrumb>
                {subjects && (
                    <Col md={4} className={"mb-5"}>
                        <p className={"lead"}>1. Выберите предмет</p>
                        <ListGroup>{subjectsList}</ListGroup>
                    </Col>
                )}
                {selectedSubjectId && (
                    <Col md={4} className={"mb-5"}>
                        <p className={"lead"}>2. Что будете делать?</p>
                        <ListGroup>
                            <ListGroup.Item
                                action
                                as={Link}
                                to={"/methodics/graph"}
                            >
                                Работать над графом
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                as={Link}
                                to={"/methodics/trainer"}
                            >
                                Добавлять задачи тренажер
                            </ListGroup.Item>
                            <ListGroup.Item
                                action
                                as={Link}
                                to={"/methodics/editquestion"}
                            >
                                Редактировать задания
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                )}
                <Col md={4} className={"mb-5"}>
                    <Button
                        variant="danger"
                        onClick={() => {
                            history.push("/");
                            persistor.purge();
                        }}
                    >
                        Очистить все данные
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
