import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: null,
        // role: (1, 'superadmin'), (2, 'methodics'), (3, 'author')
        user: {},
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.token = null;
            state.user = {};
        },
        updateUser: (state, action) => {
            state.user = Object.assign({}, state.user, action.payload);
        },
    },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser, logout, updateUser } = authSlice.actions;

export default authSlice.reducer;
