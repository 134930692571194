import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Spinner, Breadcrumb } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { getConceptsBySubjectTagId } from "../../features/subjects/subjectsApi";
import Concept from "./Concept";
import {
    findSelectedSubject,
    findConceptById,
    findSelectedExamById,
} from "../../features/subjects/subjectsSelectors";
import {
    selectConceptForSubject,
    selectExamForSubject,
} from "../../features/subjects/subjectsSlice";
import ExamTrainer from "../ExamTrainer";

export default function Subject({ subjectTagId }) {
    const selectedSubject = useSelector((state) => findSelectedSubject(state));

    const dispatch = useDispatch();

    const exams = selectedSubject.exams;
    const concepts = selectedSubject.concepts;
    const [isConceptsLoading, setIsConceptsLoading] = useState(false);

    const selectedConceptId = selectedSubject.selectedConceptId;
    const selectedConcept = useSelector((state) =>
        findConceptById(state, selectedConceptId)
    );

    const selectedExamId = selectedSubject.selectedExamId;
    const selectedExam = useSelector((state) =>
        findSelectedExamById(state, subjectTagId, selectedExamId)
    );

    useEffect(() => {
        if (concepts.length === 0) {
            setIsConceptsLoading(true);
            getConceptsBySubjectTagId(subjectTagId).then((data) => {
                setIsConceptsLoading(false);
            });
        }
    }, [subjectTagId, concepts]);

    return (
        <Card className="mb-5">
            <Card.Body>
                {selectedConceptId && (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item active>
                                {selectedSubject.title}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {selectedConcept.concept.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Button
                            variant={"secondary"}
                            className={"mb-3"}
                            onClick={() => {
                                dispatch(
                                    selectConceptForSubject({
                                        subjectId: selectedSubject.id,
                                        conceptId: null,
                                    })
                                );
                            }}
                        >
                            ◀︎ Назад
                        </Button>
                        <Concept conceptTagId={selectedConceptId} />
                    </>
                )}
                {selectedExamId && (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item active>
                                {selectedSubject.title}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Тренажер {selectedExam.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Button
                            variant={"secondary"}
                            className={"mb-3"}
                            onClick={() => {
                                dispatch(
                                    selectExamForSubject({
                                        subjectId: selectedSubject.id,
                                        examId: null,
                                    })
                                );
                            }}
                        >
                            ◀︎ Назад
                        </Button>
                        <ExamTrainer />
                    </>
                )}
                {!selectedConceptId && !selectedExamId && (
                    <>
                        <Breadcrumb>
                            <Breadcrumb.Item active>
                                {selectedSubject.title}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                            <Col>
                                {isConceptsLoading ? (
                                    <Spinner animation="grow" />
                                ) : (
                                    concepts &&
                                    concepts.length > 0 && (
                                        <>
                                            <p>Концепты</p>
                                            <ListGroup>
                                                {concepts.map((conceptTag) => (
                                                    <ListGroup.Item
                                                        variant={"primary"}
                                                        action
                                                        key={
                                                            conceptTag.concept
                                                                .id
                                                        }
                                                        onClick={() => {
                                                            dispatch(
                                                                selectConceptForSubject(
                                                                    {
                                                                        subjectId:
                                                                            selectedSubject.id,
                                                                        conceptId:
                                                                            conceptTag.id,
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {
                                                            conceptTag.concept
                                                                .title
                                                        }
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </>
                                    )
                                )}
                            </Col>
                            <Col>
                                {exams && exams.length > 0 && (
                                    <>
                                        <p>Подготовка к экзаменам</p>
                                        {exams.map((exam) => (
                                            <div className="d-grid gap-2">
                                                <Button
                                                    variant={"primary"}
                                                    key={exam.examTagId}
                                                    onClick={() => {
                                                        dispatch(
                                                            selectExamForSubject(
                                                                {
                                                                    subjectId:
                                                                        selectedSubject.id,
                                                                    examId: exam.exam_tag_id,
                                                                }
                                                            )
                                                        );
                                                    }}
                                                >
                                                    Тренажер {exam.title}
                                                </Button>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    );
}
