import React, { useEffect, useState } from "react";
import { Card, Breadcrumb, Row, Col } from "react-bootstrap";
import { Spinner, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Graph from "react-graph-vis";
import {
    loadConceptsForSubject,
    loadSubjectGraph,
    getThemes,
} from "../../../features/methodics/methodicsApi";
import { selectConceptForSubject } from "../../../features/methodics/methodicsReducer";
import { useHistory } from "react-router-dom";
import { Concepts } from "./Concepts";
import { calculateGraphVisData, graphOptions } from "./helpers";
import {
    calculateGraphForConcept,
    prepareNodesForSelect,
    calculateGraphForNode,
} from "./helpers";
import { GraphItems } from "./GraphItems";
import { NodeQuestionsPanel } from "../../../components/methodics/NodeQuestionsPanel";
import { useResizeDetector } from "react-resize-detector";

export function GraphCreation() {
    let history = useHistory();
    const dispatch = useDispatch();

    // role: (1, 'superadmin'), (2, 'methodics'), (3, 'author')
    const user = useSelector((store) => store.auth.user);

    const globalData = useSelector((store) => store.methodics);

    const selectedSubjectTagId = globalData.selectedSubjectId;
    const selectedSubject = globalData.subjects.find(
        (obj) => obj.id === selectedSubjectTagId
    );
    const subjectGraph = selectedSubject.graph;

    const selectedConceptTagId = selectedSubject.selectedConceptId;
    const subjectConcepts = selectedSubject.concepts;
    const subjectThemes = selectedSubject.themes;
    const selectedConcept = selectedSubject.concepts.find(
        (obj) => obj.id === selectedConceptTagId
    );

    // Переменная для обращения к методам vis.js
    const [network, setNetwork] = useState();
    const [focusMode, setFocusMode] = useState(false);

    const [selectedNodeId, setSelectedNodeId] = useState();
    const selectedNode = subjectGraph.nodes.find(
        (obj) => obj.id === selectedNodeId
    );
    const [secondNode, setSecondNode] = useState(null);
    const [showAddEdgeModal, setShowAddEdgeModal] = useState(false);

    const [selectedEdgeId, setSelectedEdgeId] = useState();
    const [nodesForSelect, setNodesForSelect] = useState([]);
    const [avaiableNodesForSelect, setAvaiableNodesForSelect] = useState([]);

    const [isSubjectGraphLoading, setIsSubjectGraphLoading] = useState(false);
    const [isSubjectConceptsLoading, setIsSubjectConceptsLoading] =
        useState(false);

    const [showGrayNodes, setShowGrayNodes] = useState(false);

    const [graphHeight, setGraphHeight] = useState(550);

    const { width, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: "debounce",
        refreshRate: 100,
    });

    // ОБРАЩЕНИЯ К API
    const loadGraph = () => {
        setIsSubjectGraphLoading(true);
        loadSubjectGraph(selectedSubjectTagId).then((data) => {
            setIsSubjectGraphLoading(false);
        });
    };

    const loadConcepts = () => {
        setIsSubjectConceptsLoading(true);
        loadConceptsForSubject(selectedSubjectTagId).then((data) => {
            setIsSubjectConceptsLoading(false);
        });
    };

    const loadThemes = () => {
        getThemes().then((data) => {});
    };

    // ОБРАБОТЧИКИ НАЖАТИЙ
    const onConceptSelect = (conceptId) => {
        dispatch(
            selectConceptForSubject({
                subjectId: selectedSubjectTagId,
                conceptId: conceptId,
            })
        );
        if (!conceptId) {
            setShowGrayNodes(false);
        }
    };

    // ЛОГГИРОВАНИЕ
    const showCurrentData = () => {
        console.log("Текущие данные", JSON.parse(JSON.stringify(globalData)));
    };

    useEffect(() => {
        if (subjectGraph.nodes.length === 0) {
            loadGraph();
        }
        if (subjectConcepts.length === 0) {
            loadConcepts();
        }
        if (subjectThemes.length === 0) {
            loadThemes();
        }
    }, []);

    // set new graph data and options
    useEffect(() => {
        if (network && !focusMode) {
            let graphData = subjectGraph;
            if (selectedConceptTagId) {
                console.log("Redraw graph for concept", showGrayNodes);
                network.setOptions(graphOptions("hierarchical"));
                graphData = calculateGraphForConcept(
                    subjectGraph,
                    selectedConceptTagId,
                    showGrayNodes
                );
            } else {
                console.log("Redraw big graph");
                network.setOptions(graphOptions());
            }
            network.setData(calculateGraphVisData(graphData));
        }
    }, [subjectGraph, selectedConceptTagId, showGrayNodes, network, focusMode]);

    // redefine for select
    useEffect(() => {
        let graphData = subjectGraph;
        if (selectedConceptTagId) {
            graphData = calculateGraphForConcept(
                subjectGraph,
                selectedConceptTagId,
                showGrayNodes
            );
        }
        setNodesForSelect(
            prepareNodesForSelect(subjectGraph.nodes, selectedNodeId)
        );
        setAvaiableNodesForSelect(
            prepareNodesForSelect(graphData.nodes, selectedNodeId)
        );
    }, [subjectGraph, selectedConceptTagId, network, selectedNodeId]);

    useEffect(() => {
        if (network && focusMode && selectedNodeId) {
            console.log("Redraw in focus mode");
            let graphData = subjectGraph;
            network.setOptions(graphOptions("hierarchical"));
            graphData = calculateGraphForNode(subjectGraph, selectedNodeId);
            setNodesForSelect(
                prepareNodesForSelect(subjectGraph.nodes, selectedNodeId)
            );
            setAvaiableNodesForSelect(
                prepareNodesForSelect(graphData.nodes, selectedNodeId)
            );
            network.setData(calculateGraphVisData(graphData));
        }
    }, [subjectGraph, network, focusMode, selectedNodeId]);

    useEffect(() => {
        if (network) {
            if (selectedNodeId) {
                if (selectedConceptTagId) {
                    if (selectedNode.data.concept === selectedConceptTagId) {
                        network.selectNodes([selectedNodeId]);
                    }
                } else {
                    network.selectNodes([selectedNodeId]);
                }
                if (!selectedConcept) {
                    network.focus(selectedNodeId, { scale: 0.8 });
                }
            } else if (!selectedEdgeId) {
                network.unselectAll();
            }
        }
    }, [
        network,
        selectedNodeId,
        selectedEdgeId,
        selectedConceptTagId,
        subjectGraph,
        focusMode,
        selectedConcept,
    ]);

    useEffect(() => {
        setGraphHeight(width / 1.8 > 550 ? width / 1.8 : 550);
    }, [width]);

    const events = {
        hold: function (event) {
            if (event.nodes.length !== 0) {
                setSelectedEdgeId(null);
                setSelectedNodeId(event.nodes[0]);
                setFocusMode(true);
            }
        },
        select: function (event) {
            if (event.nodes.length !== 0) {
                setSelectedEdgeId(null);
                // если открыта модалка с созданием связи, нужно выбранную вершину вставить в secondNode
                if (showAddEdgeModal) {
                    let secondNode = subjectGraph.nodes.find(
                        (obj) => obj.id === event.nodes[0]
                    );
                    if (secondNode.id !== selectedNodeId) {
                        setSecondNode({
                            value: event.nodes[0],
                            label: secondNode.data.title,
                        });
                        // prevent vis.js auto-selection
                        network.selectNodes([selectedNodeId]);
                    }
                } else {
                    setSelectedNodeId(event.nodes[0]);
                }
            } else if (event.edges.length !== 0) {
                setSelectedEdgeId(event.edges[0]);
            } else {
                if (selectedEdgeId) {
                    setSelectedEdgeId(null);
                } else if (selectedNodeId) {
                    setSelectedNodeId(null);
                    setFocusMode(false);
                }
                setShowAddEdgeModal(false);
                setSecondNode(null);
            }
        },
    };

    return (
        <>
            <Row className="mt-3">
                <Col xs={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href={"#"}
                            onClick={() => history.push("/")}
                        >
                            Главная
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            href={"#"}
                            onClick={() => history.push("/methodics")}
                        >
                            Интерфейс методиста
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Граф по предмету "{selectedSubject.subject.title}"
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className={"mb-5"}>
                <Col xs={12} md={5} lg={3} xxl={2}>
                    {isSubjectGraphLoading ? (
                        <Card border="warning" className="mb-3">
                            <Card.Body>
                                <Card.Text>Граф предмета загружается</Card.Text>
                                <Spinner animation="grow" />
                            </Card.Body>
                        </Card>
                    ) : (
                        false
                    )}
                    <Concepts
                        isSubjectConceptsLoading={isSubjectConceptsLoading}
                        loadConcepts={loadConcepts}
                        onConceptSelect={onConceptSelect}
                        showGrayNodes={showGrayNodes}
                        selectedSubject={selectedSubject}
                        subjectConcepts={subjectConcepts}
                        selectedConcept={selectedConcept}
                        setShowGrayNodes={setShowGrayNodes}
                        focusMode={focusMode}
                        user={user}
                    />
                    <GraphItems
                        selectedNodeId={selectedNodeId}
                        selectedEdgeId={selectedEdgeId}
                        selectedSubject={selectedSubject}
                        onConceptSelect={onConceptSelect}
                        setSelectedNodeId={setSelectedNodeId}
                        setSelectedEdgeId={setSelectedEdgeId}
                        nodesForSelect={nodesForSelect}
                        avaiableNodesForSelect={avaiableNodesForSelect}
                        focusMode={focusMode}
                        user={user}
                        secondNode={secondNode}
                        setSecondNode={setSecondNode}
                        showAddEdgeModal={showAddEdgeModal}
                        setShowAddEdgeModal={setShowAddEdgeModal}
                    />
                    <Card className="mb-3">
                        <Card.Body>
                            <p>Дополнительно</p>
                            <ButtonGroup vertical>
                                {user.role && user.role === 1 ? (
                                    <Button onClick={showCurrentData}>
                                        Console.log данных
                                    </Button>
                                ) : (
                                    false
                                )}
                                <Button
                                    onClick={loadGraph}
                                    disabled={isSubjectGraphLoading}
                                >
                                    Перезагрузить граф
                                </Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={7} lg={9} xxl={10}>
                    <Card style={{ height: graphHeight }} ref={ref}>
                        <Graph
                            graph={{ nodes: [], edges: [] }}
                            options={graphOptions()}
                            events={events}
                            getNetwork={(network) => setNetwork(network)}
                        />
                        {selectedNodeId && selectedNode.testability && (
                            <NodeQuestionsPanel
                                nodeId={selectedNodeId}
                                subjectId={selectedSubjectTagId}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    );
}
